import { Fragment, useEffect, useRef, useState } from "react"
import { useController, useFormContext } from "react-hook-form"
import api from "src/services/api"
import { styled } from "src/stitches.config"

import { IFileContent } from "../../../services/types"
import { CloseAssetsIcon } from "../../assets-icons"
import { Button } from "../../button"
import { HiddenFileInput } from "../../edit-forms"
import { HiddenInput } from "../../forms"

interface Props {
  endpoint: string
  name: string
  buttonLabel: string
  acceptTypes: string | string[]
  placeholder?: string
  onDarkBackground?: boolean
  extraPostData?: Record<string, unknown>
  isDisabled?: boolean
}

const UploadInputWrapper = styled("div", {
  display: "flex",
})

const FilenamePreview = styled("div", {
  position: "relative",
  flex: "1 1 auto",
  height: "44px",
  marginRight: "$10",
  padding: "$10",
  overflow: "hidden",
  whiteSpace: "nowrap",
  fontStyle: "italic",
  borderRadius: "4px",
  backgroundColor: "$grayLight",

  "& > span": {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    padding: "$10 $30 $10 $10",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },

  variants: {
    isPlaceholder: {
      true: {
        color: "$gray",
      },
    },
    onDarkBackground: {
      true: {
        backgroundColor: "$white",
      },
    },
    isDisabled: {
      true: {
        backgroundColor: "hsl(0, 0%, 95%)",
        border: "1px solid $bodyGreen",
        color: "hsl(0, 0%, 60%)",
      },
    },
  },
})

const ClearButton = styled("button", {
  position: "absolute",
  top: "0",
  right: "0",
  width: "42px",
  height: "42px",
  lineHeight: "1",
  appearance: "none",
  border: "none",
  background: "none",
  opacity: "0.5",
  transition: "opacity 0.25s ease",

  "&:hover, &:focus": {
    opacity: "1",
  },
})

const InputError = styled("div", {
  color: "$red",
})

export const UploadInput = ({
  endpoint,
  extraPostData,
  name,
  buttonLabel,
  acceptTypes,
  placeholder,
  isDisabled = false,
  ...rest
}: Props) => {
  const { setValue, watch } = useFormContext()
  const { field, fieldState } = useController({
    name: name,
  })
  const [isUploading, setIsUploading] = useState(false)
  const fileInput = useRef<HTMLInputElement>(null)
  const [fileInputContent, setFileInputContent] =
    useState<IFileContent | null>()
  const value = watch(name)

  const openFile = () => {
    if (fileInput.current !== null) {
      fileInput.current.click()
    }
  }

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filesContent = event.target.files

    if (filesContent?.length) {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(filesContent[0])
      fileReader.onload = (_e: ProgressEvent<FileReader>) => {
        if (_e?.target) {
          setFileInputContent({
            name: filesContent[0]?.name.toLowerCase(),
            content: _e.target.result as string,
          })
        }
      }
    }
  }

  function handleUploadClick() {
    openFile()
  }

  function handleClearClick() {
    if (name.endsWith(".inspectionReport.url")) {
      const certificationName = name.replace(
        ".inspectionReport.url",
        ".inspectionReport"
      )
      setValue(`${certificationName}.inspectionDate`, "", {
        shouldValidate: true,
        shouldDirty: true,
      })
      setValue(certificationName, null, {
        shouldValidate: true,
        shouldDirty: true,
      })
      setFileInputContent(null)
    }
    setValue(name, null, { shouldValidate: true, shouldDirty: true })
    setFileInputContent(null)
  }

  useEffect(() => {
    if (fileInputContent?.content) {
      setIsUploading(true)

      api.admin
        .uploadFile(
          endpoint,
          fileInputContent.name,
          fileInputContent.content,
          extraPostData
        )
        .then(({ url }) =>
          setValue(name, url, { shouldValidate: true, shouldDirty: true })
        )
        .finally(() => setIsUploading(false))
    }
  }, [fileInputContent])

  return (
    <Fragment>
      <HiddenInput name={name} />
      <HiddenFileInput
        ref={fileInput}
        type="file"
        accept={acceptTypes as string}
        onChange={onFileChange}
      />
      <UploadInputWrapper>
        <FilenamePreview
          isPlaceholder={!value}
          onDarkBackground={rest.onDarkBackground}
          isDisabled={isDisabled}
        >
          {value ? (
            <Fragment>
              <span>{value.split("/").reverse()[0]}</span>
              {!isDisabled && (
                <ClearButton
                  data-cy="upload-input-clear-button"
                  type="button"
                  onClick={handleClearClick}
                >
                  <CloseAssetsIcon />
                </ClearButton>
              )}
            </Fragment>
          ) : (
            placeholder
          )}
        </FilenamePreview>
        <Button
          data-cy="upload-input-button"
          type="button"
          variant="secondary"
          isLoading={isUploading}
          icon="upload"
          onClick={handleUploadClick}
          disabled={isDisabled}
        >
          {buttonLabel}
        </Button>
      </UploadInputWrapper>
      {fieldState.error && <InputError>{fieldState.error.message}</InputError>}
    </Fragment>
  )
}
