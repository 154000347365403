import { useMutation } from "@tanstack/react-query"
import { useRouter } from "next/router"
import { toast } from "react-hot-toast"
import { accommodationService, v2Routes } from "src/services"
import { Accommodation } from "src/services/v2/accommodation"

export function useDeleteAccommodation(id: string) {
  const router = useRouter()

  async function mutationFn() {
    const response = await accommodationService.delete(id)
    return response.data
  }

  return useMutation(mutationFn, {
    onSuccess: (data: Accommodation) => {
      toast.success(`${data.name} is verwijderd`)

      const { source } = v2Routes.accommodations.all
      router.push(source)
    },
    onError: (error) => {
      toast.error(
        `Verwijderen accommodatie is niet gelukt. Probeer het nog een keer of neem contact met ons op (${error}).`
      )
    },
  })
}
