import { IDropdownOption } from "@types"
import { Fragment, forwardRef, useEffect, useState } from "react"
import { useController, useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"
import { MultiSelect } from "react-multi-select-component"
import { SelectProps as ISelectProps } from "react-multi-select-component/dist"
import { iconSizes } from "src/constants"
import useIntlOption from "src/hooks/useIntlOption"
import { styled } from "src/stitches.config"

import { Close2Icon } from "../assets-icons"
import { DropdownArrow } from "../dropdown-arrow"
import { Overlay } from "../overlay"
import {
  DropdownFiltersWrapper,
  DropdownItemRenderer,
  EmptyInputPlaceholder,
  InputItem,
} from "./fragments"

interface Props {
  name?: string
  placeholder?: string
  disableSearch?: ISelectProps["disableSearch"]
  disabled?: ISelectProps["disabled"]
  options?: ISelectProps["options"]
  activeOptions?: ISelectProps["value"]
  /**
   *
   *
   * @type {boolean}
   * @memberof Props used if the dropdown item should be rendered in two lines (the lable needs to have ' <wELeAz,69eXW#hN,r@ ' string in it for this to work)
   */
  twoLines?: boolean
  filterOptions?: ISelectProps["filterOptions"]
  onChange?: (options: IDropdownOption[]) => void
  error?: string
  minWidth?: "xsmall" | "small" | "medium" | "large"
  max?: number
}

interface WrappedProps extends Props {
  name: string
  max?: number
}

const InputError = styled("div", {
  color: "$red",
})

const StyledDropDownArrow = () => (
  <div style={{ paddingRight: "6px", paddingBottom: "4px" }}>
    <DropdownArrow />
  </div>
)

const _filterOptions = (options: IDropdownOption[], filter: string) => {
  if (!filter) {
    return options
  }
  const re = new RegExp(filter, "i")
  return options.filter(({ label }) => label && label.match(re))
}

export const MultiselectDropdown = forwardRef(
  (
    {
      minWidth = "large",
      placeholder,
      disableSearch,
      disabled = false,
      options = [],
      activeOptions = [],
      filterOptions,
      onChange,
      error,
      twoLines = false,
      max,
      ...rest
    }: Props,
    ref
  ) => {
    const intlOption = useIntlOption()
    const { formatMessage } = useIntl()
    const [isFocused, setIsFocused] = useState(false)
    const [currentActiveOptions, setActiveOptions] = useState(
      activeOptions || []
    )

    useEffect(() => {
      if (onChange) {
        onChange(currentActiveOptions)
      }
      if (max) {
        if (currentActiveOptions.length > max) {
          setActiveOptions(currentActiveOptions.splice(-1))
        }
      }
    }, [currentActiveOptions])

    function handleChange(values: IDropdownOption[]) {
      setActiveOptions(values)
    }

    const customValueRenderer = (
      selected: IDropdownOption[],
      _options: IDropdownOption[]
    ) => {
      return selected.length ? (
        selected.map((renderingOption, index) => {
          function handleRemove(event: any) {
            event.stopPropagation()
            setActiveOptions(
              currentActiveOptions.filter(
                ({ value }) => value !== renderingOption.value
              )
            )
          }
          return (
            <InputItem
              data-cy="selectedFilterOption"
              key={`${renderingOption.value}_${index}`}
              label={intlOption(renderingOption.label) as string}
              onRemove={disabled ? () => {} : handleRemove}
            />
          )
        })
      ) : (
        <EmptyInputPlaceholder>
          {placeholder ||
            formatMessage({ id: `form.${rest.name}.placeholder` })}
        </EmptyInputPlaceholder>
      )
    }
    const fullOptions = (
      twoLines
        ? options.map((o) => ({ ...o, twoLines: twoLines })).map(intlOption)
        : options.map(intlOption)
    ) as IDropdownOption[]

    return (
      <Fragment>
        {isFocused && <Overlay />}
        <DropdownFiltersWrapper
          noSearch={disableSearch}
          isActive={isFocused}
          minWidth={minWidth}
          isDisabled={disabled}
        >
          <input type="hidden" {...rest} value={""} />
          <MultiSelect
            labelledBy="Select"
            hasSelectAll={false}
            options={fullOptions}
            value={currentActiveOptions}
            onChange={handleChange}
            valueRenderer={customValueRenderer}
            ItemRenderer={DropdownItemRenderer}
            ClearSelectedIcon={<></>}
            ClearIcon={
              <Close2Icon color="$colors$inputBorder" size={iconSizes.xsmall} />
            }
            ArrowRenderer={StyledDropDownArrow}
            overrideStrings={{
              noOptions: formatMessage({ id: "filters.noResults" }),
              search: formatMessage({ id: "filters.search" }),
            }}
            filterOptions={filterOptions || _filterOptions}
            disableSearch={disableSearch}
            onMenuToggle={setIsFocused}
            disabled={disabled}
          />

          {error ? <InputError>{error}</InputError> : null}
        </DropdownFiltersWrapper>
      </Fragment>
    )
  }
)

// The same component, but connected to the ReactHookForm context
export const WrappedMultiselectDropdown = ({
  name,
  options,
  placeholder,
  max,
  ...props
}: WrappedProps) => {
  const { watch, setValue } = useFormContext()
  const { field, formState, fieldState } = useController({
    name: name,
    shouldUnregister: true,
  })

  const value = watch(name)
  const error =
    ((formState.isSubmitted || fieldState.isTouched || fieldState.invalid) &&
      fieldState.error?.message) ||
    ""

  useEffect(() => {
    if (error) {
      // set value and force re-validation when there is an error
      setValue(name, value, { shouldValidate: true, shouldDirty: true })
    }
  }, [value])

  return (
    <MultiselectDropdown
      key={`${name}_${value?.length}`} // hacky way of forcing a rerender when values change
      {...props}
      {...field}
      placeholder={placeholder}
      options={options}
      activeOptions={value}
      error={error}
      max={max}
    />
  )
}

MultiselectDropdown.displayName = "MultiselectDropdown"
