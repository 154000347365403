import React, { useCallback } from "react"
import { useController, useFormContext } from "react-hook-form"

interface Props {
  helpText?: string
  displayAs?: React.FC
  trimLineBreaks?: boolean
  italic?: boolean
  name: string
  label?: string
  placeholder?: string
  defaultValue?: string
  bigPadding?: boolean
  inputRef?: React.Ref<HTMLInputElement>
  autoFocus?: boolean
}

export const HiddenInput = ({
  trimLineBreaks,
  name,
  defaultValue,
  placeholder,
  autoFocus,
  inputRef,
}: Props) => {
  const { setValue } = useFormContext()
  const { field } = useController({
    name: name,
    defaultValue: defaultValue,
    shouldUnregister: true,
  })

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let { value, name } = event.target

      if (trimLineBreaks) {
        value = value.replace(/(\r\n|\n|\r)/gm, "")
      }

      setValue(name, value, { shouldValidate: true, shouldDirty: true })
    },
    []
  )

  return (
    <input
      {...field}
      type="hidden"
      placeholder={placeholder}
      onChange={handleChange}
      ref={inputRef}
      autoFocus={autoFocus}
      data-cy="hidden-input"
    />
  )
}
