import { DefaultSession } from "next-auth"
import { useSession } from "next-auth/react"
import { IOrganization, TUser } from "src/services"

interface SessionWithPermissions extends DefaultSession {
  permissions?: string[]
  roles?: string[]
}

export interface SessionWithPermissionsAndSub extends DefaultSession {
  permissions?: string[]
  roles?: string[]
  sub?: string
}

interface SessionWithSub extends DefaultSession {
  sub?: string
}

export enum UserRoles {
  FUNCTIONAL_ADMIN = "functional-admin",
  INSTITUTE_EMPLOYEE = "institute-employee",
  SPORT_UNION_REPRESENTATIVE = "sport-union-representative",
  PUBLIC = "public",
  MANUFACTURER = "manufacturer",
  CONTRACTOR_EMPLOYEE = "contractor-employee",
}

export default function hasPermissions(
  session: SessionWithPermissions | null,
  permissions: string[]
): boolean {
  return !!session?.permissions?.find((permission) => {
    return permissions.includes(permission)
  })
}

export function hasRoles(
  session: SessionWithPermissions | null,
  roles: string[]
): boolean {
  return !!session?.roles?.find((role) => {
    return roles.includes(role)
  })
}

export function useHasPermissions(permissions: string[]) {
  const { data: session } = useSession()
  if (!session) {
    return false
  }
  //@ts-ignore
  return !!session?.permissions?.find((permission) => {
    return permissions.includes(permission)
  })
}

export function useHasRoles(roles: string[]) {
  const { data: session } = useSession()
  if (!session) {
    return false
  }
  //@ts-ignore
  return !!session?.roles?.find((role) => {
    return roles.includes(role)
  })
}

export function useIsAdmin() {
  return useHasPermissions(["getPermissions"])
}

export function isOrganizationUser(
  session: SessionWithSub | null,
  organization: IOrganization
): boolean {
  if (!session?.sub) return false
  return !!organization?.users
    ?.map((user: TUser) => user.externalId?.toLowerCase())
    .includes(session.sub.toLowerCase())
}

export function isUnionUser(
  sub: string | null | undefined,
  users: Partial<TUser>[]
): boolean {
  if (!sub) return false
  return !!users
    .map((user) => user.externalId?.toLowerCase())
    .includes(sub.toLowerCase())
}
