import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query"
import { toast } from "react-hot-toast"
import { notesService } from "src/services"

import { CertificationProcessCreateNote } from "../types"

export function useCertificationProcessCreateNote(
  certificationProcessId: string,
  options: UseMutationOptions<
    { content: string },
    unknown,
    CertificationProcessCreateNote,
    unknown
  >
) {
  const queryClient = useQueryClient()

  function mutateFn(data: CertificationProcessCreateNote) {
    return notesService.create(certificationProcessId, data)
  }

  return useMutation(mutateFn, {
    onSuccess: (data, variables, context) => {
      options.onSuccess?.(data, variables, context)

      toast.success("Notitie toegevoegd")

      queryClient.refetchQueries([
        "certification-process",
        certificationProcessId,
        "notes",
      ])
    },
  })
}
