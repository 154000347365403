import {
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from "@tanstack/react-query"
import { organizationsService } from "src/services"

import { OrganizationGetAllFilters } from "../organizations.service"
import { OrganizationGetAll } from "../types"

export function useOrganizations(
  filters: OrganizationGetAllFilters,
  options?: UseQueryOptions
): UseQueryResult<OrganizationGetAll> {
  const queryFn = () => organizationsService.getAll(filters)

  return useQuery(["organizations", filters], queryFn, {
    initialData: options?.initialData,
    enabled: options?.enabled,
  })
}
