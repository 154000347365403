import { FormEventHandler, useEffect, useState } from "react"
import { useFieldArray } from "react-hook-form"
import { useIntl } from "react-intl"
import { OrganizationSelector } from "src/components/organization-selector"
import {
  IDisciplineCertificate,
  IImplementedSportProduct,
  IOrganization,
} from "src/services"
import { styled } from "src/stitches.config"
import { getMapCity } from "src/utils/get-map-city"

import { ContentBox } from "../../context-box"
import {
  Form,
  FormInput,
  FormItem,
  FormItemInput,
  FormItemLabel,
  LocationType,
  MapModal,
} from "../../forms"
import { Grid } from "../../grid"
import { GridItem } from "../../grid-item"
import { H1, P } from "../../typography"
import { LocationButton } from "../location-button"
import { ImplementedSportProduct } from "./fragments"

interface Props {
  onSubmit: FormEventHandler<HTMLFormElement>
  form: any
}

const TitleWrapper = styled("div", {
  display: "flex",
  alignItems: "flex-start",
})

const OwnerItem = styled("div", {
  "&:not(:last-child)": {
    marginBottom: "$20",
  },

  "@mdUp": {
    display: "flex",

    "& > *:first-child": {
      flex: "0 0 180px",
      marginTop: "$5",
    },

    "& > *:last-child": {
      flex: "1 1",
      padding: 0,
    },
  },
})

export const AccommodationForm = ({ onSubmit, form }: Props) => {
  const { formatMessage } = useIntl()
  const { setValue, watch } = form
  const [isMapOpen, setIsMapOpen] = useState(false)

  const selectedLatitute = watch("latitude")
  const selectedLongitude = watch("longitude")
  const selectedOwner = watch("owner")

  const implementedSportProducts = useFieldArray({
    keyName: "_id",
    name: "implementedSportProducts",
    control: form.control,
  })

  const handleLocationClick = () => {
    setIsMapOpen(true)
  }

  const handleMapClose = () => {
    setIsMapOpen(false)
  }

  const saveLocationClick = async (location: LocationType) => {
    setValue("location", `${location.lat},${location.lng}`, {
      shouldValidate: true,
      shouldDirty: true,
    })
    setValue("latitude", location.lat.toString(), {
      shouldValidate: true,
      shouldDirty: true,
    })
    setValue("longitude", location.lng.toString(), {
      shouldValidate: true,
      shouldDirty: true,
    })

    const city = await getMapCity(location.lat, location.lng)

    if (city) {
      handleMapClose()
    }

    setValue(`city`, city, { shouldValidate: true, shouldDirty: true })
  }

  useEffect(() => {
    if (selectedLatitute && selectedLongitude) {
      setValue("location", `${selectedLatitute},${selectedLongitude}`)
    }
  }, [selectedLongitude, selectedLatitute])

  const isFullyRejected = (
    implementedSportProduct: IImplementedSportProduct
  ): boolean => {
    const disciplineCount = implementedSportProduct.disciplines.length
    const rejectedCertificates =
      implementedSportProduct.disciplineCertificates.filter(
        (certificate: IDisciplineCertificate) =>
          certificate.state === "REJECTED"
      )
    return rejectedCertificates.length === disciplineCount
  }

  function handleOrganizationChange(organization?: IOrganization) {
    setValue("owner", organization)
  }

  return (
    <Form onSubmit={onSubmit} form={form}>
      <Grid>
        <GridItem col={[1, 12]}>
          <ContentBox>
            <TitleWrapper>
              <FormInput
                displayAs={H1}
                name="name"
                trimLineBreaks
                bigPadding
                css={{ marginBottom: "$20", flexGrow: 2, marginRight: "$10" }}
                placeholder={formatMessage({
                  id: "form.accommodation.name.placeholder",
                })}
                maxLength={25}
                data-cy="form-input-title"
              />
            </TitleWrapper>
            <FormItem horizontal>
              <FormItemLabel htmlFor="location">
                {formatMessage({ id: "form.accommodation.location.label" })}
              </FormItemLabel>
              <FormItemInput
                data-cy="form-input-location"
                css={{
                  "@mdUp": { maxWidth: "600px" },
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                <FormInput
                  displayAs={P}
                  name="location"
                  trimLineBreaks
                  italic
                  placeholder={formatMessage({
                    id: "admin.createProject.location.placeholder",
                  })}
                  isDisabled
                  css={{ flexGrow: 2, marginRight: "$10" }}
                />
                <LocationButton onClick={handleLocationClick} />
                <MapModal
                  isOpen={isMapOpen}
                  onCancelClick={handleMapClose}
                  onLocationSave={saveLocationClick}
                  defaultLocation={
                    selectedLatitute && selectedLongitude
                      ? { lat: selectedLatitute, lng: selectedLongitude }
                      : null
                  }
                />
              </FormItemInput>
            </FormItem>
            <OwnerItem>
              <FormItemLabel htmlFor="owner.name">
                {formatMessage({ id: "form.accommodation.owner.label" })}
              </FormItemLabel>
              <OrganizationSelector
                value={selectedOwner}
                onChange={handleOrganizationChange}
              />
            </OwnerItem>
          </ContentBox>
        </GridItem>
      </Grid>

      {implementedSportProducts.fields.map(
        (implementedSportProduct: any, index: number) => {
          if (isFullyRejected(implementedSportProduct)) return null

          return (
            <ImplementedSportProduct
              key={implementedSportProduct._id}
              implementedSportProduct={implementedSportProduct}
              path={`implementedSportProducts.${index}`}
            />
          )
        }
      )}
    </Form>
  )
}
