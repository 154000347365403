import { IDropdownOption } from "@types"
import { TSportClub } from "src/services"
import { styled } from "src/stitches.config"

interface Props {
  sportClubs: IDropdownOption[]
}

const SportClubsGridWrapper = styled("div", {
  display: "grid",
  gridColumnGap: "$10",
  gridRowGap: "$10",
  gridTemplateColumns: "repeat(1, 1fr)",

  "@mdUp": {
    gridTemplateColumns: "repeat(2, 1fr)",
  },

  "@lgUp": {
    gridTemplateColumns: "repeat(3, 1fr)",
  },
})

const SportClubGridItem = styled("div", {
  padding: "$10",
  border: "1px #CBD4C2 solid",
  borderRadius: "4px",
  backgroundColor: "#F0F0F0",
})

export const SportClubsGrid = ({ sportClubs = [] }: Props) => {
  return (
    <SportClubsGridWrapper>
      {sportClubs.map((sportClub) => {
        const { kvkNumber, name, address, city }: TSportClub = JSON.parse(
          sportClub.value
        )
        return (
          <SportClubGridItem key={kvkNumber}>
            <strong>{name}</strong>
            <br />
            {address}
            <br />
            {city}
            <br />
          </SportClubGridItem>
        )
      })}
    </SportClubsGridWrapper>
  )
}
