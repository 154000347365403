import {
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from "@tanstack/react-query"
import {
  SportProductSubtype,
  SportProductSubtypeFilters,
  sportProductSubtypeService,
} from "src/services"

export function useSportProductSubtypes(
  filters: SportProductSubtypeFilters,
  options?: UseQueryOptions
): UseQueryResult<SportProductSubtype[]> {
  const queryFn = () => sportProductSubtypeService.getAll(filters)
  return useQuery(["sport-product-subtypes", filters], queryFn, {
    enabled: options?.enabled,
  })
}
