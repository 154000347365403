import {
  UseQueryOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query"
import { toast } from "react-hot-toast"
import { disciplinesService } from "src/services"
import { V2IspDisciplines } from "src/utils/v2"

export function useManageDisciplines(
  cpId: string,
  ispId: string,
  options?: UseQueryOptions
) {
  const queryClient = useQueryClient()

  async function mutateFn(data: V2IspDisciplines) {
    const result =
      await disciplinesService.manageImplementedSportProductDisciplines(
        cpId,
        ispId,
        data
      )

    await queryClient.refetchQueries(["certification-process", cpId, "implemented-sport-products"]) // prettier-ignore
    await queryClient.refetchQueries(["certification-processes", cpId])
    return result
  }

  return useMutation(mutateFn, {
    onSuccess: () => {
      toast.success(`Disciplines geupdate`)

      if (options?.onSuccess) {
        options.onSuccess({})
      }
    },
  })
}
