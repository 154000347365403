import { useCallback, useContext, useMemo, useState } from "react"
import { FieldError, useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"
import { EditHandbookContext } from "src/context/edit-handbook-context"
import { styled } from "src/stitches.config"

import { Button } from "../../button"
import { Input } from "../../input"
import { P } from "../../typography"

const DocumentCodeContainer = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-end",
  height: "100%",

  [`> ${Button}`]: {
    marginLeft: "$10",
  },
})

const StyledButton = styled(Button, {
  alignSelf: "flex-end",
})

const DocumentCodeInputWrap = styled("span", {
  maxWidth: "10rem",
})

const INPUT_STATES = {
  IDLE: "idle",
  VALIDATING: "validating",
  VALID: "valid",
  INVALID: "invalid",
}

export const DocumentCodeInput = () => {
  const { formatMessage } = useIntl()
  const { mode } = useContext(EditHandbookContext)
  const [state, setState] = useState(
    mode === "edit" ? INPUT_STATES.VALID : INPUT_STATES.IDLE
  )
  const { watch, setValue, formState, setError, clearErrors } = useFormContext()
  const codeValue = watch("code")
  const title = watch("title")

  const validateCode = useCallback(async () => {
    try {
      setState(INPUT_STATES.VALIDATING)
      const resp = await fetch(`/api/handbook/${codeValue}`)

      if (resp.status === 200) {
        setError("code", {
          message: formatMessage({ id: "edit.code.alreadyExists" }),
        })
        setState(INPUT_STATES.INVALID)
      } else if (resp.status === 404) {
        clearErrors("code")
        setState(INPUT_STATES.VALID)
      } else {
        setError("code", { message: resp.statusText })
        setValue("code", "", { shouldValidate: true, shouldDirty: true })
        setState(INPUT_STATES.INVALID)
      }
    } catch (err) {
      console.error({ err })
    }
  }, [codeValue])

  const handleEditClick = useCallback(() => {
    setState(INPUT_STATES.IDLE)
  }, [])

  const isBusy = useMemo(() => state === INPUT_STATES.VALIDATING, [state])

  return (
    <DocumentCodeContainer>
      {state === INPUT_STATES.VALID ? (
        <>
          <P bold noMargin style={{ alignSelf: "center" }}>
            {codeValue}
          </P>
          {mode === "new" && (
            <Button
              size="small"
              variant="light"
              icon="edit"
              onClick={handleEditClick}
              aria-label="edit"
              data-cy="edit-title"
            />
          )}
        </>
      ) : (
        <>
          <DocumentCodeInputWrap>
            <Input
              data-cy="form-input-code"
              name="code"
              size="small"
              variant="secondary"
              placeholder="C0103"
              onChange={(code: string) =>
                setValue("code", code, {
                  shouldValidate: true,
                  shouldDirty: true,
                })
              }
              withClearButton={false}
              error={formState.errors.code?.message as FieldError["message"]}
              disabled={isBusy}
            />
          </DocumentCodeInputWrap>

          <StyledButton
            size="regular"
            icon="checkmark"
            variant="secondary"
            onClick={validateCode}
            isLoading={isBusy}
            disabled={isBusy || codeValue === ""}
            aria-label="check-code"
          />
        </>
      )}
    </DocumentCodeContainer>
  )
}
