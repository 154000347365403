import { UseQueryResult, useQuery } from "@tanstack/react-query"
import { implementedSportProductService } from "src/services"
import {
  ImplementedSportProduct,
  IspGetAllByAccIdFilters,
} from "src/services/v2/implemented-sport-product"

export function useImplementedSportProductsByAccommodation(
  id: string,
  filters?: IspGetAllByAccIdFilters
): UseQueryResult<ImplementedSportProduct[]> {
  const queryFn = () =>
    implementedSportProductService.getAllByAccommodationId(id, filters)

  return useQuery(
    ["accommodation", id, "implemented-sport-products", filters],
    queryFn
  )
}
