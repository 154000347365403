import { useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { MultiselectDropdown } from "src/components/multiselect-dropdown"
import { ORGANIZATION_TYPES } from "src/constants"
import useKVKSearch from "src/hooks/useKVKSearch"
import { IOrganization, useOrganizations } from "src/services"
import alphabeticalSort from "src/utils/alphabetical-sort"
import { transformers } from "src/utils/mappers"
import { V2Owner } from "src/utils/v2"

import { ArrowRightAssetsIcon, SearchAssetsIcon } from "../assets-icons"
import { DropdownSelect, Option } from "../dropdown-select"
import { FormItem, FormItemInput } from "../forms"
import { OwnerToggleButton } from "../owner-toggle-button"

type Mode = ORGANIZATION_TYPES.MISC | ORGANIZATION_TYPES.MUNICIPALITIES

const defaultMode = ORGANIZATION_TYPES.MISC

interface Props {
  value?: Option | IOrganization | V2Owner
  onChange: (value: IOrganization | undefined) => any
  disableMunicipalitySelect?: boolean
}

export const OrganizationSelector = ({
  value,
  onChange,
  disableMunicipalitySelect = false,
}: Props) => {
  const { data: municipalities = [] } = useOrganizations({
    type: ORGANIZATION_TYPES.MUNICIPALITIES,
  })
  const { formatMessage } = useIntl()
  const { filter: kvkOptionsFilter } = useKVKSearch()
  const [mode, setMode] = useState<Mode>()

  const municipalityOptions = useMemo(() => {
    return municipalities.map(transformers.municipality).sort(alphabeticalSort)
  }, [municipalities])

  const selectedValue: Option | undefined = useMemo(() => {
    if (!value) return undefined

    return (value as Option).value
      ? transformers.municipality(JSON.parse((value as Option).value))
      : transformers.municipality(value)
  }, [value])

  function handleToggleModeClick() {
    onChange(undefined)

    if (mode === ORGANIZATION_TYPES.MISC) {
      setMode(ORGANIZATION_TYPES.MUNICIPALITIES)
    }

    if (mode === ORGANIZATION_TYPES.MUNICIPALITIES) {
      setMode(ORGANIZATION_TYPES.MISC)
    }
  }

  function handleMunicipalityChange(value: Option) {
    onChange(JSON.parse(value.value))
  }

  function handleKvkSelectChange(value: Option[]) {
    onChange(
      value[0]?.value
        ? { ...JSON.parse(value[0].value), type: ORGANIZATION_TYPES.MISC }
        : null
    )
  }

  useEffect(() => {
    const parsedValue = selectedValue && JSON.parse(selectedValue.value).type
    if (parsedValue === ORGANIZATION_TYPES.MUNICIPALITIES) {
      return setMode(ORGANIZATION_TYPES.MUNICIPALITIES)
    }

    setMode(defaultMode)
  }, [])

  // Owner input with municipality dropdown
  if (
    !disableMunicipalitySelect &&
    mode === ORGANIZATION_TYPES.MUNICIPALITIES
  ) {
    return (
      <FormItem>
        <FormItemInput noMargin data-cy="form-input-owner">
          <DropdownSelect
            onChange={handleMunicipalityChange}
            selectedOption={selectedValue}
            options={municipalityOptions}
            placeholder={formatMessage({
              id: "form.select.placeholder.municipality",
            })}
            noDefault
          />
        </FormItemInput>
        <OwnerToggleButton
          type="button"
          onClick={handleToggleModeClick}
          data-cy="owner-toggle-button"
        >
          <span>
            <SearchAssetsIcon />
            {formatMessage({ id: "admin.createProject.ownerSection.kvk" })}
          </span>
        </OwnerToggleButton>
      </FormItem>
    )
  }

  // Owner input with KVK dropdown (default)
  return (
    <FormItem>
      <FormItemInput noMargin data-cy="form-input-owner">
        <MultiselectDropdown
          activeOptions={selectedValue ? [selectedValue] : []}
          placeholder={formatMessage({ id: "form.select.placeholder.kvk" })}
          filterOptions={kvkOptionsFilter}
          onChange={handleKvkSelectChange}
          minWidth="medium"
          max={1}
        />
      </FormItemInput>

      {!disableMunicipalitySelect && (
        <OwnerToggleButton
          type="button"
          onClick={handleToggleModeClick}
          data-cy="owner-toggle-button"
        >
          <span>
            <ArrowRightAssetsIcon />
            {formatMessage({
              id: "admin.createProject.ownerSection.municipality",
            })}
          </span>
        </OwnerToggleButton>
      )}
    </FormItem>
  )
}
