import {
  UseQueryOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query"
import { useRouter } from "next/router"
import { toast } from "react-hot-toast"
import { accommodationService, v2Routes } from "src/services"
import { Accommodation } from "src/services/v2/accommodation"
import { V2CreateAccommodation } from "src/utils/v2"

export function useCreateAccommodation(options?: UseQueryOptions) {
  const router = useRouter()
  const queryClient = useQueryClient()
  async function mutationFn(data: V2CreateAccommodation) {
    return accommodationService.create(data)
  }

  return useMutation(mutationFn, {
    onSuccess: async (data: Accommodation) => {
      toast.success("Accommodatie is aangemaakt")

      const { source } = v2Routes.accommodations.detail
      await router.push(source.replace(":id", data.id))
      queryClient.invalidateQueries(["accommodation", data.id])
      queryClient.invalidateQueries(["accommodations"])
      if (options?.onSuccess) {
        options?.onSuccess(data)
      }
    },
    onError: (error) => {
      toast.error(
        `Aanmaken accommodatie is niet gelukt. Probeer het nog een keer of neem contact met ons op (${error}).`
      )
    },
  })
}
