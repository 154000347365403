import { AxiosResponse } from "axios"
import { V2CreateAccommodation } from "src/utils/v2"

import { V2Api } from "../api.v2"
import {
  Accommodation,
  AccommodationListItems,
  AccommodationPassport,
} from "./types"

export class AccommodationService extends V2Api {
  public async getAll(): Promise<AccommodationListItems> {
    return super.get(`/accommodations`)
  }

  public async getById(id: string): Promise<AxiosResponse<Accommodation>> {
    return super.get(`/accommodation/${id}`)
  }

  public async getPassportById(id: string): Promise<AccommodationPassport> {
    return super.get(`/accommodation/${id}/passport`)
  }

  public async create(
    data: Partial<V2CreateAccommodation>
  ): Promise<Accommodation> {
    return super.post(`/accommodation`, { data })
  }

  public async update(
    id: string,
    data: Partial<Accommodation>
  ): Promise<Accommodation> {
    return super.put(`/accommodation/${id}`, { data })
  }

  public async delete(id: string): Promise<AxiosResponse<Accommodation>> {
    return super.delete(`/accommodation/${id}`)
  }
}
