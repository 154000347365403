import { useSession } from "next-auth/react"
import Link from "next/link"
import { Fragment, useMemo } from "react"
import { useIntl } from "react-intl"
import geometricShapes from "src/assets/geometric-shapes.png"
import {
  AccommodationData,
  AccommodationPassport,
  TUserOrganizations,
} from "src/services"
import { lightOnDark, styled } from "src/stitches.config"
import {
  getApprovedCertificates,
  getApprovedDisciplineNames,
  getApprovedSportNames,
  useAccommodationPermissions,
} from "src/utils/accommodation"
import { SessionWithPermissionsAndSub } from "src/utils/has-permissions"

import { Button } from "../button"
import { Message } from "../message"
import { H5, P } from "../typography"
import { DownloadCertificateLink } from "./fragments/download-certificate-link"

interface Props {
  token: string
  reportToken: string
  userOrganizations: TUserOrganizations
  accommodation: AccommodationData | AccommodationPassport
}

const ImplementedSportProductCardWrapper = styled("div", {})

const ImplementedSportProductCardBody = styled("div", {
  padding: "$40 $40 $5",
  color: "$gray",
  backgroundColor: "$white",
})

const ImplementedSportProductCardFooter = styled("div", {
  position: "relative",
  padding: "$60 $40 $20",
  backgroundColor: "$blue",
  backgroundImage: `url(${geometricShapes.src})`,

  "&::before, &::after": {
    position: "absolute",
    top: "0",
    right: "0",
    left: "0",
    height: "$40",
    transform: "translateY(-50%)",
    content: "''",
  },

  "&::before": {
    backgroundColor: "$white",
    clipPath: "polygon(0 0, 100% 0, 100% 50%, 0 100%)",
  },

  "&::after": {
    backgroundColor: "red",
    clipPath: "polygon(0 90%, 100% 40%, 100% 50%, 0 100%)",
  },
})

const Table = styled("div", {
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
})

const TableLabel = styled(P, {
  marginBottom: "$20",
})

const TableValue = styled(P, {
  marginBottom: "$20",
})

export const ImplementedSportProductCard = ({
  token,
  reportToken,
  accommodation,
  userOrganizations,
}: Props) => {
  const { data: session } = useSession()
  const { formatMessage } = useIntl()
  const isAdmin = (
    session as SessionWithPermissionsAndSub
  )?.permissions?.includes("getPermissions")

  const { canViewCertificates, canViewReport, canViewApprovedCertificate } =
    useAccommodationPermissions({
      userOrganizations,
      accommodation,
    })

  const implementedSportProduct = accommodation.implementedSportProducts[0]

  const approvedSportNames = useMemo(() => {
    return getApprovedSportNames(implementedSportProduct)
  }, [implementedSportProduct])

  const approvedDisciplineNames = useMemo(() => {
    return getApprovedDisciplineNames(implementedSportProduct)
  }, [implementedSportProduct])

  const approvedCertificates = useMemo(() => {
    return getApprovedCertificates({
      canViewApprovedCertificate,
      implementedSportProduct,
      userOrganizations,
    })
  }, [])

  const hasFaultyCertificates =
    !approvedCertificates.length ||
    Boolean(
      approvedCertificates.find(
        (approvedCertificate) =>
          !approvedCertificate.certificate ||
          !Boolean(approvedCertificate.certificate.url)
      )
    )

  return (
    <ImplementedSportProductCardWrapper data-cy="sportproduct-wrapper">
      <ImplementedSportProductCardBody data-cy="sportproduct-card">
        <H5
          data-cy="sportproduct-name"
          css={{ marginBottom: "$20", "@lgDown": { fontSize: "20px" } }}
        >
          {implementedSportProduct.sportProduct.name}
        </H5>
        <Table>
          <TableLabel data-cy="producttype-label">
            {formatMessage({ id: "implementedSportProduct.producttype" })}
          </TableLabel>
          <TableValue data-cy="producttype-value">
            <Message
              id={`sportsProductType.${implementedSportProduct.sportProduct.type.name}`}
            />
          </TableValue>
          <TableLabel data-cy="subtype-label">
            {formatMessage({ id: "implementedSportProduct.eigenschap" })}
          </TableLabel>
          <TableValue data-cy="subtype-value">
            {implementedSportProduct.sportProduct.subtype.name}
          </TableValue>
          <TableLabel data-cy="contractor-label">
            {formatMessage({
              id: "implementedSportProduct.contractor",
            })}
          </TableLabel>
          <TableValue coloredAnchors data-cy="contractor-value">
            {isAdmin ||
            implementedSportProduct.contractor?.isActive === true ? (
              <Link
                href={`/organisaties/${implementedSportProduct.contractor.id}`}
                className="underlined"
              >
                {implementedSportProduct.contractor.name}
              </Link>
            ) : (
              implementedSportProduct.contractor.name
            )}
          </TableValue>
          {implementedSportProduct.sportProduct?.manufacturer?.name && (
            <>
              <TableLabel data-cy="manufacturer-label">
                {formatMessage({
                  id: "implementedSportProduct.manufacturer",
                })}
              </TableLabel>
              <TableValue coloredAnchors data-cy="manufacturer-value">
                {isAdmin ||
                implementedSportProduct.sportProduct.manufacturer?.isActive ===
                  true ? (
                  <Link
                    href={`/organisaties/${implementedSportProduct.sportProduct?.manufacturer?.id}`}
                    className="underlined"
                  >
                    {implementedSportProduct.sportProduct?.manufacturer?.name}
                  </Link>
                ) : (
                  implementedSportProduct.sportProduct?.manufacturer?.name
                )}
              </TableValue>
            </>
          )}
        </Table>

        {(isAdmin ||
          implementedSportProduct.sportProduct?.systemStatus?.name ===
            "active") && (
          <P coloredAnchors>
            <Link
              href={`/sportproducten/${implementedSportProduct.sportProduct.id}`}
              data-cy="sportproduct-link"
            >
              {formatMessage({
                id: "implementedSportProduct.sportProduct",
              })}
              <Button
                variant="light"
                icon="arrowRight"
                iconColor="primary"
                css={{ marginLeft: "$10" }}
              />
            </Link>
          </P>
        )}
      </ImplementedSportProductCardBody>

      <ImplementedSportProductCardFooter className={lightOnDark}>
        <Table>
          {!!approvedSportNames.length && (
            <Fragment>
              <TableLabel data-cy="approvedsports-label">
                {formatMessage({
                  id: "implementedSportProduct.sports",
                })}
              </TableLabel>
              <TableValue data-cy="approvedsports-value">
                {approvedSportNames.join(", ")}
              </TableValue>
            </Fragment>
          )}

          {!!approvedDisciplineNames.length && (
            <Fragment>
              <TableLabel data-cy="sportdiscipline-label">
                {formatMessage({
                  id: "implementedSportProduct.sportDisciplines",
                })}
              </TableLabel>
              <TableValue data-cy="sportdiscipline-value">
                {approvedDisciplineNames.join(", ")}
              </TableValue>
            </Fragment>
          )}

          <TableLabel data-cy="status-label">Status</TableLabel>
          <TableValue bold data-cy="status-value">
            {accommodation.implementedSportProducts[0].state}
          </TableValue>
        </Table>

        {approvedCertificates.map((approvedCertificate) => {
          if (approvedCertificate.certificate?.url) {
            return (
              <P as="div" key={approvedCertificate.certificate.id}>
                <DownloadCertificateLink
                  token={token}
                  disciplineCertificate={approvedCertificate}
                />
              </P>
            )
          }
        })}

        {canViewReport && implementedSportProduct.inspectionReport && (
          <P>
            <a
              data-cy="download-report"
              href={
                implementedSportProduct.inspectionReport.url + "?" + reportToken
              }
              download={true}
              target="_blank"
              className="underlined"
            >
              <Button
                variant="light"
                icon="download"
                iconColor="primary"
                css={{ marginRight: "$10" }}
              />
              {formatMessage({
                id: "implementedSportProduct.downloadRapport",
              })}
            </a>
          </P>
        )}

        {/* Is certified but at least one certificate has issues, null or missing url, show error */}
        {canViewCertificates &&
          implementedSportProduct.isApproved &&
          hasFaultyCertificates && (
            <P>
              <i>
                {formatMessage({ id: "accommodation.missingCertificate" })}{" "}
                <a
                  href={`mailto:${formatMessage({
                    id: "accommodation.missingCertificate.email",
                  })}`}
                  className="underlined"
                >
                  {formatMessage({
                    id: "accommodation.missingCertificate.emailText",
                  })}
                </a>
              </i>
            </P>
          )}
      </ImplementedSportProductCardFooter>
    </ImplementedSportProductCardWrapper>
  )
}
