import { UseQueryOptions, useMutation } from "@tanstack/react-query"
import { useRouter } from "next/router"
import { toast } from "react-hot-toast"
import { v2Routes } from "src/services"

import { certificationProcessService } from "../.."
import { CertificationProcessCreate } from "../types"

export function useCreateCertificationProcess(
  accommodationId: string,
  options: UseQueryOptions
) {
  const router = useRouter()

  function mutateFn(data: CertificationProcessCreate) {
    return certificationProcessService.create(accommodationId, data)
  }

  return useMutation(mutateFn, {
    onSuccess: (...args) => {
      toast.success("Certificeringsproject aangemaakt")
      options.onSuccess?.(args)

      const { source } = v2Routes.certificationProcess.detail
      router.push(source.replace(":id", args[0].id))
    },
  })
}
