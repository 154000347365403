import React, { PropsWithChildren, ReactElement } from "react"
import { styled } from "src/stitches.config"

import { PageWidthContainer } from "../../page-width-container"
import { H1, P } from "../../typography"
import { DisciplinePageHeaderVisual } from "../discipline-page-header-visual"

const DisciplinePageHeaderWrapper = styled("header", {
  position: "relative", // need this for positioning the DisciplinePageHeaderVisual component
  marginBottom: "$80",
  backgroundColor: "$orangeLight",

  "@mdDown": {
    marginBottom: "$40",
  },
})

const DisciplinePageHeaderBody = styled("div", {
  position: "relative",
  padding: "$40 0 $10",

  [H1.toString()]: {
    marginBottom: "$20",
    color: "$white",
    lineHeight: "1.15",
    textShadow:
      "-1px 0 $colors$orange, 0 1px $colors$orange, 1px 0 $colors$orange, 0 -1px $colors$orange",
  },

  [P.toString()]: {
    marginBottom: "0",
    color: "$white",
  },

  "@mdDown": {
    padding: "$20 0 0",
  },
})

export const DisciplinePageHeader = (
  props: PropsWithChildren<{}>
): ReactElement => (
  <DisciplinePageHeaderWrapper>
    <DisciplinePageHeaderVisual />

    <PageWidthContainer>
      <DisciplinePageHeaderBody>{props.children}</DisciplinePageHeaderBody>
    </PageWidthContainer>
  </DisciplinePageHeaderWrapper>
)
