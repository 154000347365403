import type * as Stitches from "@stitches/react"
import { PropsWithChildren, ReactElement } from "react"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import gfm from "remark-gfm"
import { styled } from "src/stitches.config"

import { H1, H2, H3, H4, P } from "../typography"

const MarkdownWrapper = styled("span", {
  a: {
    color: "$blue",
    textDecoration: "underline",

    "&:hover, &:focus": {
      color: "$blue",
      textDecoration: "underline",
      opacity: 0.75,
    },
  },

  "p, ul, ol": {
    marginBottom: "$40",

    "@mdDown": {
      marginBottom: "$20",
    },

    li: {
      marginBottom: "$20",

      "&:last-child": {
        marginBottom: 0,
      },
    },

    "&:last-child": {
      marginBottom: 0,
    },
  },

  "ul, ol": {
    paddingLeft: "1.3rem",
  },

  table: {
    minWidth: "100%",

    "@mdDown": {
      th: {
        fontSize: "8px",
      },

      td: {
        fontSize: "9px",
      },
    },

    "@mdUp": {
      th: {
        fontSize: "11px",
      },

      td: {
        fontSize: "12px",
      },
    },

    "th, td": {
      padding: "$10",
      fontWeight: "$regular",
      textAlign: "center",
      wordBreak: "break-all",
    },

    "td:first-child": {
      textAlign: "left",
    },

    "td:not(:first-child)": {
      fontWeight: "$bold",
    },

    "tbody tr:nth-child(odd)": {
      backgroundColor: "rgba(255, 255, 255, 0.6)",
    },
  },
})

const components = {
  h1(props: any) {
    return <H1>{props.children}</H1>
  },
  h2(props: any) {
    return <H2>{props.children}</H2>
  },
  h3(props: any) {
    return <H3>{props.children}</H3>
  },
  h4(props: any) {
    return <H4>{props.children}</H4>
  },
  p(props: any) {
    return <P>{props.children}</P>
  },
}

interface MarkdownProps {
  css?: Stitches.CSS
}

export const Markdown = (
  props: PropsWithChildren<MarkdownProps>
): ReactElement => {
  const { children, ...rest } = props

  return (
    <MarkdownWrapper data-cy="markdown" {...rest}>
      <ReactMarkdown
        disallowedElements={["script"]}
        remarkPlugins={[gfm]}
        rehypePlugins={[rehypeRaw]}
        components={components}
      >
        {children as string}
      </ReactMarkdown>
    </MarkdownWrapper>
  )
}
