import {
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from "@tanstack/react-query"
import { accommodationService } from "src/services"
import { Accommodation } from "src/services/v2/accommodation"

export function useAccommodation(
  id: string,
  options?: UseQueryOptions
): UseQueryResult<Accommodation> {
  const queryFn = () => accommodationService.getById(id)

  return useQuery(["accommodation", id], queryFn, {
    initialData: options?.initialData,
    placeholderData: options?.initialData,
    enabled: !!id,
  })
}
