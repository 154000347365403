import {
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from "@tanstack/react-query"
import { SportProductType, sportProductTypeService } from "src/services"

export function useSportProductTypes(
  options?: UseQueryOptions
): UseQueryResult<SportProductType[]> {
  const queryFn = () => sportProductTypeService.getAll()
  return useQuery(["sport-product-types"], queryFn, {
    enabled: options?.enabled,
  })
}
