import { captureException } from "@sentry/nextjs"
import {
  UseQueryOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query"
import { toast } from "react-hot-toast"

import { certificationService } from "../.."
import { CertificationManage } from "../types"

export function useManageCertification(
  cpId: string,
  options?: UseQueryOptions
) {
  const queryClient = useQueryClient()

  async function mutateFn(data: CertificationManage) {
    if (data.note === null || data.note === "" || data.note === undefined) {
      delete data.note
    }
    if (data.validForYears && typeof data.validForYears === "string") {
      data.validForYears = parseInt(data.validForYears)
    }

    // @ts-ignore
    const result = await certificationService.manage(data)

    if (cpId) {
      await queryClient.refetchQueries([
        "certification-processes",
        "certify-data",
        cpId,
      ])
    }

    return result
  }

  return useMutation(mutateFn, {
    onSuccess: (...args) => {
      toast.success("Certification managed")
      options?.onSuccess?.(args)
    },
    onError: (error) => {
      toast.error(String(error))
      captureException(error)
    },
  })
}

export function useReturnCertificationProcess(cpId: string) {
  async function mutateFn() {
    return await certificationService.returnCertificationProcess(cpId)
  }

  return useMutation(mutateFn, {
    onSuccess: () => {
      toast.success("Certificeringsproject teruggezet naar het instituut")
    },
    onError: (error) => {
      toast.error(String(error))
      captureException(error)
    },
  })
}
