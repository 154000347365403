import { useRouter } from "next/router"
import Script from "next/script"
import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
} from "react"
import appConfig from "src/config"

type AnalyticsProviderProps = {
  registerEvent: (input: Record<string, unknown>) => void
}

export const AnalyticsContext = createContext<AnalyticsProviderProps>({
  registerEvent: () => {
    console.warn("Please use within the analytics context")
  },
})

export const AnalyticsProvider: FC<PropsWithChildren> = ({ children }) => {
  const router = useRouter()

  const registerEvent: AnalyticsProviderProps["registerEvent"] = useCallback(
    (input) => {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push(input)
    },
    []
  )

  useEffect(() => {
    const onRouteChange = (url: string) => {
      if (!appConfig.gtmId) {
        return
      }

      registerEvent({
        event: "pageview",
        page: url,
      })
    }

    router.events.on("routeChangeComplete", onRouteChange)

    return () => {
      router.events.off("routeChangeComplete", onRouteChange)
    }
  }, [router.events, registerEvent])

  return (
    <AnalyticsContext.Provider value={{ registerEvent }}>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${appConfig.gtmId}`}
      />
      {children}
    </AnalyticsContext.Provider>
  )
}
