import { useSession } from "next-auth/react"
import { Fragment, useCallback, useEffect, useState } from "react"
import { FormProvider, UseFormReturn } from "react-hook-form"
import { useIntl } from "react-intl"
import { styled } from "src/stitches.config"
import hasPermissions from "src/utils/has-permissions"

import { Button } from "../button"
import { FilterContainer } from "../filter-container"
import { FiltersContainer } from "../filters-container"
import { WrappedMultiselectDropdown } from "../multiselect-dropdown"
import { H3 } from "../typography"

interface Props {
  form: UseFormReturn
  options: { [key: string]: any }
}

const ShowMoreButton = styled("button", {
  appearance: "none",
  display: "flex",
  alignItems: "center",
  alignSelf: "flex-end",

  font: "inherit",
  color: "$secondary",
  border: "none",
  cursor: "pointer",
  background: "none",

  "> *:first-child": {
    marginRight: "1rem",
  },

  "&:hover": {
    "> *:first-child": {
      textDecoration: "underline",
    },
    "> *:last-child": {
      color: "$primary",
    },
  },

  marginLeft: "auto",

  "@mdDown": {
    marginTop: "$20",
    marginBottom: "$20",
  },
})

type BasicFilter = {
  name: string
  option: string
  label: string
  placeholder: string
  minWidth: "xsmall" | "small" | "medium" | "large"
  disableSearch: boolean
}

const basicFilters: BasicFilter[] = [
  {
    name: "discipline",
    option: "disciplines",
    label: "disciplines",
    placeholder: "disciplines",
    minWidth: "large",
    disableSearch: false,
  },
  {
    name: "type",
    option: "types",
    label: "type",
    placeholder: "type",
    minWidth: "medium",
    disableSearch: true,
  },
  {
    name: "subtype",
    option: "subtypes",
    label: "subtype",
    placeholder: "subtypes",
    minWidth: "medium",
    disableSearch: false,
  },
]

// TODO Yulia: split on fragments

/** Component with filters for sport products
 * @param {UseFormReturn} form
 * @param { [key: string]: any} options
 */
export const SportsProductsFilters = ({ form, options }: Props) => {
  const { data: session } = useSession()
  const { formatMessage } = useIntl()
  const [showMore, setShowMore] = useState(false)

  const handleShowMoreClick = useCallback(() => {
    setShowMore(!showMore)
  }, [showMore, setShowMore])

  useEffect(() => {
    const { manufacturer, productStatus, systemStatus, contractors } =
      form.getValues()
    if (
      !!manufacturer?.length ||
      !!productStatus?.length ||
      !!systemStatus?.length ||
      !!contractors?.length
    ) {
      setShowMore(true)
    }
  }, [])

  return (
    <FormProvider {...form}>
      <form data-cy="sport-products-filter">
        <FiltersContainer css={{ marginTop: "0", position: "relative" }}>
          {basicFilters.map(
            ({ name, option, label, placeholder, minWidth, disableSearch }) => (
              <FilterContainer
                key={`filter-${name}`}
                data-cy={`filter-${name}`}
              >
                <H3 as="p">
                  {formatMessage({ id: `filters.${label}.label` })}
                </H3>
                <WrappedMultiselectDropdown
                  minWidth={minWidth}
                  name={name}
                  options={options[option] || undefined}
                  placeholder={formatMessage({
                    id: `filters.${placeholder}.placeholder`,
                  })}
                  disableSearch={disableSearch}
                />
              </FilterContainer>
            )
          )}

          {/* Temp hidden until NOCNSF decides to activate it */}
          {/* <FilterContainer data-cy='filter-sustainabilityLabels'>
            <H3 as='p'>{formatMessage({ id: 'filters.sustainabilityLabels.label' })}</H3>
            <WrappedMultiselectDropdown
              minWidth='small'
              name='sustainabilityLabel'
              options={options?.sustainabilityLabels}
              placeholder={formatMessage({ id: 'filters.sustainabilityLabels.placeholder' })}
              disableSearch
            />
          </FilterContainer> */}

          {showMore && (
            <Fragment>
              <FilterContainer data-cy="filter-productStatuses">
                <H3 as="p">
                  {formatMessage({ id: "filters.productStatuses.label" })}
                </H3>
                <div>
                  <WrappedMultiselectDropdown
                    minWidth="medium"
                    name="productStatus"
                    options={options?.productStatuses}
                    placeholder={formatMessage({
                      id: "filters.productStatuses.placeholder",
                    })}
                    disableSearch
                  />
                </div>
              </FilterContainer>

              {hasPermissions(session, ["getSportProductSystemStatuses"]) && (
                <FilterContainer data-cy="filter-systemStatuses">
                  <H3 as="p">
                    {formatMessage({ id: "filters.systemStatuses.label" })}
                  </H3>
                  <div>
                    <WrappedMultiselectDropdown
                      minWidth="medium"
                      name="systemStatus"
                      options={options?.systemStatuses}
                      placeholder={formatMessage({
                        id: "filters.systemStatuses.placeholder",
                      })}
                      disableSearch
                    />
                  </div>
                </FilterContainer>
              )}

              <FilterContainer data-cy="filter-manufacturers">
                <H3 as="p">
                  {formatMessage({ id: "filters.manufacturers.label" })}
                </H3>
                <div>
                  <WrappedMultiselectDropdown
                    minWidth="medium"
                    name="manufacturer"
                    options={options?.manufacturers}
                    placeholder={formatMessage({
                      id: "filters.manufacturers.placeholder",
                    })}
                  />
                </div>
              </FilterContainer>

              <FilterContainer data-cy="filter-contractors">
                <H3 as="p">
                  {formatMessage({ id: "filters.contractors.label" })}
                </H3>
                <div>
                  <WrappedMultiselectDropdown
                    minWidth="medium"
                    name="contractors"
                    options={options?.contractors}
                    placeholder={formatMessage({
                      id: "filters.contractors.placeholder",
                    })}
                  />
                </div>
              </FilterContainer>
            </Fragment>
          )}

          {!showMore && (
            <ShowMoreButton
              data-cy="show-more-button"
              type="button"
              onClick={handleShowMoreClick}
            >
              <span>Toon meer filters</span>
              <Button as="div" icon="filter" variant="light" size="regular" />
            </ShowMoreButton>
          )}
        </FiltersContainer>
      </form>
    </FormProvider>
  )
}
