import { UseQueryResult, useQuery } from "@tanstack/react-query"
import { CertificationProcess, certificationProcessService } from "src/services"

export function useCertificationProcessesByAccommodationId(
  id: string
): UseQueryResult<CertificationProcess[]> {
  const queryFn = () => {
    return certificationProcessService.getAllByAccommodationId(id)
  }

  return useQuery(["accommodation", id, "certification-processes"], queryFn)
}
