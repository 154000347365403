import { UseQueryResult, useQuery } from "@tanstack/react-query"
import { disciplinesService } from "src/services"

import { IspDisciplineGetAllAvailable } from "../types"

export function useIspAvailableDisciplines(
  ispId: string
): UseQueryResult<IspDisciplineGetAllAvailable> {
  const queryFn = () =>
    disciplinesService.getImplementedSportProductAllAvailableDisciplines(ispId)

  return useQuery(["disciplines", ispId, "implemented-sport-product"], queryFn)
}
