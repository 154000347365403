import { AxiosResponse } from "axios"

import { V2Api } from "../api.v2"
import {
  ImplementedSportProduct,
  ImplementedSportProductCreate,
  ImplementedSportProductUpdate,
} from "./types"

export interface IspGetAllByAccIdFilters {
  isRetired: boolean
}

export class ImplementedSportProductService extends V2Api {
  public async create(accId: string, data: ImplementedSportProductCreate) {
    return super.post(`/accommodation/${accId}/implemented-sport-product`, { data }) // prettier-ignore
  }

  public async update(id: string, data: ImplementedSportProductUpdate) {
    return super.put(`/implemented-sport-product/${id}`, { data })
  }

  public async replace(id: string, data: ImplementedSportProductUpdate) {
    return super.post(`/implemented-sport-product/${id}/replace`, { data })
  }

  public async retire(id: string) {
    return super.post(`/implemented-sport-product/${id}/retire`)
  }

  public async delete(id: string) {
    return super.delete(`/implemented-sport-product/${id}`)
  }

  public async getById(id: string) {
    return super.get(`/implemented-sport-product/${id}`)
  }

  public async getAllByAccommodationId(
    accommodationId: string,
    filters?: IspGetAllByAccIdFilters
  ): Promise<AxiosResponse<ImplementedSportProduct>> {
    return super.get(`/accommodation/${accommodationId}/implemented-sport-products`, { params: filters }) // prettier-ignore
  }

  public async getAllByCertificationProcessId(certificationProcessId: string) {
    return super.get(`/certification-process/${certificationProcessId}/implemented-sport-products`) // prettier-ignore
  }
}
