import { UseQueryResult, useQuery } from "@tanstack/react-query"
import { implementedSportProductService } from "src/services"
import { ImplementedSportProduct } from "src/services/v2/implemented-sport-product"

export function useImplementedSportProductsByCertificationProcess(
  id: string
): UseQueryResult<ImplementedSportProduct[]> {
  const queryFn = () =>
    implementedSportProductService.getAllByCertificationProcessId(id)

  return useQuery(
    ["certification-process", id, "implemented-sport-products"],
    queryFn
  )
}
