import {
  UseQueryOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query"
import { toast } from "react-hot-toast"
import { accommodationService } from "src/services"
import { Accommodation } from "src/services/v2/accommodation"

export function useUpdateAccommodation(id = "", options?: UseQueryOptions) {
  const queryClient = useQueryClient()

  async function mutationFn(data: Accommodation) {
    return accommodationService.update(id, data)
  }

  return useMutation(mutationFn, {
    onSuccess: (data: Accommodation) => {
      options?.onSuccess?.(data)
      queryClient.invalidateQueries(["accommodation", id])
      queryClient.invalidateQueries(["accommodations"])
      toast.success(`${data.name} is bijgewerkt`)
    },
    onError: (error) => {
      toast.error(
        `Bijwerken accommodatie is niet gelukt. Probeer het nog een keer of neem contact met ons op (${error}).`
      )
    },
  })
}
