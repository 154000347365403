import React, { ReactElement } from "react"
import { IFooterContactItem } from "src/services"
import { styled } from "src/stitches.config"

import { IconWrapper } from "../../icon-wrapper"
import { H4, P } from "../../typography"

const FooterContactItemWrapper = styled("div", {
  padding: "1px",

  "@mdUp": {
    flex: "0 0 calc(100% / 3)",
  },
})

const FooterContactItemInner = styled("div", {
  position: "relative",
  display: "flex",
  alignItems: "flex-start",
  height: "100%",
  padding: "$20 $20",
  borderRadius: "4px",
  backgroundColor: "$grayLight",

  "@mdUp": {
    padding: "$30 $20 $20",
  },

  "@lgUp": {
    padding: "$40 $30 $30",
  },

  p: {
    marginBottom: "0",
  },

  "a::before": {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    content: "''",
  },
})

const FooterContactItemIcon = styled("div", {
  flex: "0 0 60px",
  height: "60px",
  marginRight: "$md",
  lineHeight: "55px",
  textAlign: "center",
  borderRadius: "50%",
  backgroundColor: "$blue",
})

const FooterContactItemBody = styled("div", {
  flex: "1 1",
})

export const FooterContactItem = (props: IFooterContactItem): ReactElement => {
  const onChatWidgetClickHandler = () => {
    // simulate a click directly on widget so it open by itself
    const widgetChatButton = document.querySelector(
      "[data-id='zsalesiq']"
    ) as HTMLElement
    if (widgetChatButton) widgetChatButton.click()
  }

  return (
    <FooterContactItemWrapper data-cy="footer-contact-item">
      <FooterContactItemInner>
        <FooterContactItemIcon data-cy="icon">
          <IconWrapper name={props.icon} />
        </FooterContactItemIcon>

        <FooterContactItemBody>
          {process.env.NODE_ENV === "production" && props.icon === "chat" ? (
            <H4 as="p">
              <a
                className="js-live-agent-button"
                onClick={onChatWidgetClickHandler}
              >
                {props.title}
              </a>
            </H4>
          ) : (
            <H4 as="p">
              <a href={props.href || "#"}>{props.title}</a>
            </H4>
          )}

          <P>{props.description}</P>
        </FooterContactItemBody>
      </FooterContactItemInner>
    </FooterContactItemWrapper>
  )
}
