import React, { Fragment, useMemo } from "react"
import { iconSizes } from "src/constants"
import { IInfrastructureRuleAttachment } from "src/services"
import { styled } from "src/stitches.config"

import { LinkIcon } from "../../assets-icons"
import { P } from "../../typography"

interface Props extends IInfrastructureRuleAttachment {
  content: string
  url: string
}

const RuleAttachmentLinkWrapper = styled("div", {
  position: "absolute",
  top: "0",
  right: "0",
  width: "280px",
  display: "flex",
  alignItems: "flex-start",
  transform: "translateX(100%)",

  a: {
    color: "$blue",
    textDecoration: "underline",
  },

  "a::after": {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    content: "''",
  },

  "@lgDown": {
    display: "none",
  },
})

const RuleAttachmentLinkIcon = styled("div", {
  position: "relative",
  flex: "0 0 $sizes$30",
  height: "$30",
  marginRight: "$10",
  marginTop: "-2px",
  textAlign: "center",
  color: "$primary",
  borderRadius: "0 4px 4px 0",
  backgroundColor: "$white",
  boxShadow: "$default",

  // Hides shadow on the left side
  "&::after": {
    position: "absolute",
    top: "-5px",
    bottom: "-5px",
    left: "-5px",
    width: "5px",
    content: "''",
    backgroundColor: "$white",
  },
})

const RuleAttachmentLinkContent = styled("div", {
  "a, a *": {
    color: "$blue",
    textDecoration: "underline",
  },

  "@lgUp": {
    display: "none",
  },
})

export const RuleAttachmentLink = (props: Props) => {
  const isExternal = useMemo(
    () => props.url?.substring(0, 4) === "http",
    [props.url]
  )

  const target = isExternal ? "_blank" : undefined
  const rel = isExternal ? "noreferrer noopener" : undefined

  return (
    <Fragment>
      <RuleAttachmentLinkWrapper data-cy="link-attachment">
        <RuleAttachmentLinkIcon>
          <LinkIcon size={iconSizes.xlarge} />
        </RuleAttachmentLinkIcon>

        <a href={props.url} target={target} rel={rel}>
          {props.title}
        </a>
      </RuleAttachmentLinkWrapper>

      <RuleAttachmentLinkContent>
        <P>
          <a href={props.url} target={target} rel={rel}>
            {props.content}
          </a>
        </P>
      </RuleAttachmentLinkContent>
    </Fragment>
  )
}
