import React, { PropsWithChildren, ReactElement } from "react"
import { styled } from "src/stitches.config"

import { PageWidthContainer } from "../../page-width-container"
import { OverviewHeaderVisual } from "../overview-header-visual"

const OverviewPageHeaderWrapper = styled("header", {
  position: "relative", // need this for positioning the OverviewPageHeaderVisual component
  display: "flex",
  alignItems: "center",
  marginBottom: "$40",
  backgroundColor: "$white",

  "@mdDown": {
    marginBottom: "$20",
  },

  "@mdUp": {
    minHeight: "431px",
  },
})

const OverviewPageHeaderBody = styled("div", {
  position: "relative",
  padding: "$40 0",

  H1: {
    marginBottom: "$20",
    lineHeight: "1.15",
  },

  P: {
    maxWidth: "825px",
    fontFamily: "$nova",
    fontWeight: "$medium",
    color: "$tertiary",
  },

  "@mdDown": {
    padding: "$20 0",
  },
})

export const OverviewPageHeader = (
  props: PropsWithChildren<{}>
): ReactElement => (
  <OverviewPageHeaderWrapper>
    <OverviewHeaderVisual />

    <PageWidthContainer>
      <OverviewPageHeaderBody>{props.children}</OverviewPageHeaderBody>
    </PageWidthContainer>
  </OverviewPageHeaderWrapper>
)
