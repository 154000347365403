import { MouseEvent, useCallback, useContext, useMemo, useState } from "react"
import { useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"
import { EditHandbookContext } from "src/context/edit-handbook-context"
import useTimeout from "src/hooks/useTimeout"
import { styled } from "src/stitches.config"
import { RULE_RULE, TEXT_EXT_RULE, TEXT_RULE } from "src/templates"

import { Button } from "../../button"
import { CreateRuleAttachment, EditableRuleAttachment } from "../../edit-forms"
import { FormInput, RemoveWithConfirmationButton } from "../../forms"
import { Grid } from "../../grid"
import { GridItem } from "../../grid-item"
import { RuleIndex, RuleType } from "../../infrastructure-page"
import { P } from "../../typography"

interface Props {
  path: string
  rule: any
  remove: Function
  removeFromIndex: number
}

const RuleTypeWrapper = styled("div", {
  variants: {
    showTooltip: {
      true: {
        [`& ${RuleIndex}`]: {
          zIndex: 1003,
        },
        [`& > div`]: {
          zIndex: 1002,
        },
      },
    },
  },
})

const InputWrapper = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  position: "relative",
})

const OptionsWrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  marginLeft: "$10",
  zIndex: "1",
  opacity: "0",
  transition: ".2s opacity linear",

  "& > *:not(:last-child)": {
    marginBottom: "calc($10 / 2)",
  },

  variants: {
    isVisible: {
      true: {
        opacity: "1",
      },
    },
  },
})

const CreateRuleAttachmentWrapper = styled("div", {
  display: "flex",
  flexDirection: "row-reverse",
})

export const EditableRule = ({
  path,
  rule,
  remove,
  removeFromIndex,
}: Props) => {
  const { handbookType } = useContext(EditHandbookContext)
  const { formatMessage } = useIntl()
  const { setValue, watch } = useFormContext()
  const [showTooltip, setShowTooltip] = useState(false)
  const [isOptionsVisible, setOptionsVisible] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const [isCreateRuleAttachmentVisible, setCreateRuleAttachmentVisible] =
    useState(false)
  const [hideCreateRuleAttachment, stopHideCreateRuleAttachment] = useTimeout(
    () => {
      setCreateRuleAttachmentVisible(false)
    },
    1000
  )

  const ruleTypePath = useMemo(() => `${path}.ruleType`, [path])
  const selectedRuleType = watch(ruleTypePath, rule.ruleType)
  const attachmentPath = useMemo(() => `${path}.attachment`, [path])
  const selectedAttachment = watch(attachmentPath, rule.attachment)

  const handleRemoveRuleClick = useCallback(() => {
    remove(removeFromIndex)
  }, [removeFromIndex, remove])

  const handleShowCreateRuleAttachmentClick = useCallback(() => {
    setCreateRuleAttachmentVisible(!isCreateRuleAttachmentVisible)
  }, [isCreateRuleAttachmentVisible, setCreateRuleAttachmentVisible])

  const handleSelectType = useCallback(
    (key: string) => {
      setValue(ruleTypePath, key, { shouldValidate: true, shouldDirty: true })
      setShowTooltip(!showTooltip)
    },
    [ruleTypePath, showTooltip]
  )

  const showTypeSelection = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault()
      setShowTooltip(!showTooltip)
    },
    [showTooltip]
  )

  const handleMouseEnter = useCallback(() => setOptionsVisible(true), [])
  const handleMouseLeave = useCallback(
    () => setOptionsVisible(isFocused),
    [isFocused]
  )
  const handleFocus = useCallback(() => {
    setIsFocused(true)
    setOptionsVisible(true)
  }, [])
  const handleBlur = useCallback(() => {
    setIsFocused(false)
    setOptionsVisible(false)
  }, [])

  const showRuleTypeSelector = useMemo(
    () => handbookType === "precept",
    [handbookType]
  )
  const attachmentsFor = useMemo(() => {
    switch (selectedRuleType) {
      case "R":
        return RULE_RULE
      case "S":
        return TEXT_EXT_RULE
      default:
        return TEXT_RULE
    }
  }, [selectedRuleType])

  return (
    <Grid columns={13} gutter={12} data-cy="rule">
      {showRuleTypeSelector && (
        <GridItem col={[1, 1]}>
          {!!rule.ruleType && (
            <RuleTypeWrapper showTooltip={showTooltip}>
              <RuleIndex />
              <RuleType
                ruleType={selectedRuleType}
                onClick={showTypeSelection}
                editType={handleSelectType}
                showTooltip={showTooltip}
              />
            </RuleTypeWrapper>
          )}
        </GridItem>
      )}

      <GridItem
        col={[showRuleTypeSelector ? 2 : 1, showRuleTypeSelector ? 12 : 13]}
      >
        <InputWrapper
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onFocus={handleFocus}
          onBlur={handleBlur}
        >
          <FormInput
            displayAs={P}
            name={`${path}.content`}
            defaultValue={rule.content}
            placeholder={formatMessage({
              id:
                showRuleTypeSelector && selectedRuleType
                  ? "admin.editRule.newRule.placeholder"
                  : "admin.editRule.newText.placeholder",
            })}
            italic
            helpText={formatMessage({ id: "admin.editRule.helpText" })}
            css={{
              flexGrow: 1,
            }}
          />

          <OptionsWrapper isVisible={isOptionsVisible && !selectedAttachment}>
            <RemoveWithConfirmationButton onConfirm={handleRemoveRuleClick} />
            <Button
              variant="secondary"
              icon={isCreateRuleAttachmentVisible ? "delete" : "plus"}
              size="small"
              onClick={handleShowCreateRuleAttachmentClick}
              data-cy="create-rule-attachment-toggle"
              aria-label={
                isCreateRuleAttachmentVisible ? "verwijder" : "toevoegen"
              }
            />
          </OptionsWrapper>
        </InputWrapper>

        {isCreateRuleAttachmentVisible && !selectedAttachment && (
          <CreateRuleAttachmentWrapper>
            <CreateRuleAttachment
              path={attachmentPath}
              attachmentsFor={attachmentsFor}
              onMouseEnter={stopHideCreateRuleAttachment}
              onMouseLeave={hideCreateRuleAttachment}
            />
          </CreateRuleAttachmentWrapper>
        )}

        {!!selectedAttachment && (
          <EditableRuleAttachment
            path={attachmentPath}
            attachment={selectedAttachment}
          />
        )}
      </GridItem>
    </Grid>
  )
}
