import React, { useMemo } from "react"
import List from "react-list-select"
import { styled } from "src/stitches.config"

import { P } from "../typography"

interface Props {
  title: string
  items: string[]
  onChange?: (selected: number) => void
  selected?: number
}

export const ListSelector = ({ title, items, onChange, selected }: Props) => {
  const selectedItem = useMemo(
    () => (selected !== undefined ? [selected] : []),
    [selected]
  )

  return (
    <>
      <DrawingModalSubtitle>{title}</DrawingModalSubtitle>
      <DrawingListWrap data-cy="drawing-list-select">
        <List items={items} onChange={onChange} selected={selectedItem} />
      </DrawingListWrap>
    </>
  )
}

const DrawingModalSubtitle = styled(P, {
  color: "$gray",
  fontFamily: "$nova",
  marginBottom: "$10",
})

const DrawingListWrap = styled("div", {
  ".react-list-select": {
    display: "inline-block",
    position: "relative",
    outline: "none",
    borderRadius: "0 4px 4px 0",
    backgroundColor: "#F0F0F0",
    boxShadow: "inset 0 2px 8px 0 rgba(74,74,74,0.1)",
    padding: "$20",
    listStyle: "none",
    width: "100%",

    "&--item": {
      cursor: "pointer",
      height: "1.75rem",
      padding: "0 $10",
      borderRadius: "4px",
      userSelect: "none",

      "&.is-focused": {
        backgroundColor: "#CBD4C266",
      },
      "&.is-selected": {
        backgroundColor: "#CBD4C2",
        userSelect: "inherit",
      },
    },
  },
})
