import { useSession } from "next-auth/react"
import React, { useCallback, useState } from "react"
import { useIntl } from "react-intl"

import { InfrastructurePageHeaderInactiveComponentWithConfirmation } from "../infrastructure-page-header-inactive-with-confirmation"

interface InactiveHeaderProps {
  hideRestore?: boolean
  onFail?: () => void
  onClick: () => void
  onSuccess?: () => void
  messageTitle: string
  headerTitle?: string
}

export const PageHeaderInactive = ({
  hideRestore,
  onFail,
  onClick,
  onSuccess,
  messageTitle,
  headerTitle,
}: InactiveHeaderProps) => {
  const { data: session } = useSession()
  const { formatMessage } = useIntl()
  const [isLoading, setIsLoading] = useState(false)

  const handleClick = useCallback(async () => {
    try {
      setIsLoading(true)
      await onClick()
      onSuccess && onSuccess()
    } catch (err) {
      console.error(err)
      onFail && onFail()
    } finally {
      setIsLoading(false)
    }
  }, [])

  return (
    <InfrastructurePageHeaderInactiveComponentWithConfirmation
      hideRestore={!session || !!hideRestore}
      onConfirm={handleClick}
      title={headerTitle || formatMessage({ id: "headers.inactive.title" })}
      subtitle={formatMessage({ id: "headers.inactive.subtitle" })}
      confirmationTitle={messageTitle}
      confirmationMessage={formatMessage(
        { id: "admin.deactivateDoc.confirmationRestoreText" },
        { code: messageTitle }
      )}
      confirmButtonLabel={formatMessage({
        id: "admin.deactivateDoc.confirmationRestoreButton",
      })}
      isLoading={isLoading}
      iconType="unarchive"
    />
  )
}
