var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"mDnguUyGdMBv9TrdTjaHj"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from "@sentry/nextjs"

import { getTracesSampleRate } from "./sentry.server.config"

Sentry.init({
  enabled: process.env.NODE_ENV === "production",
  debug: false,
  dsn: "https://fe638318269b052aa20a78f87abb70ba@o4506400358989824.ingest.sentry.io/4506422509371392",
  environment: process.env.ENVIRONMENT || "dev",
  tracesSampleRate: getTracesSampleRate(),
})
