import { useRouter } from "next/router"
import qs from "query-string"
import { useCallback } from "react"
import { useIntl } from "react-intl"
import { styled } from "src/stitches.config"

import { Button } from "../button"
import { Message } from "../message"

const Wrapper = styled("div", {
  display: "flex",
  alignItems: "center",

  "&:hover": {
    color: "$orangeLight",
    button: { color: "$orangeLight" },
    span: {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },

  "& > *:not(:last-child)": {
    marginRight: ".5rem",
  },

  variants: {
    alignBottom: {
      true: {
        alignItems: "flex-end",

        [`${Button}`]: {
          marginBottom: "-3px",
        },
      },
    },
  },
})

const Label = styled("span", {
  fontFamily: "$grotesk",
  color: "$blue",
  variants: {
    white: {
      true: {
        color: "$white",
      },
    },
  },
})

export interface FilterResetProps {
  filterNames?: string[]
  onChange?: (filters: string[]) => void
  alignBottom?: boolean
  noIcon?: boolean
  inverseVariant?: boolean
}

function filterObject(obj: Object, callback: Function) {
  return Object.fromEntries(
    Object.entries(obj).filter(([key, val]) => callback(val, key))
  )
}

export const FilterReset = ({
  filterNames,
  onChange,
  alignBottom,
  noIcon,
  inverseVariant,
}: FilterResetProps) => {
  const router = useRouter()
  const { formatMessage } = useIntl()

  const resetFilters = useCallback(() => {
    const activeQuery = router.query
    const asPathBase = router.asPath.split("?")[0]

    if (filterNames?.length) {
      // remove matching url queries
      const newQuery = filterObject(
        activeQuery,
        (_val: any, key: string) => !filterNames.includes(key)
      )
      router.push(`${asPathBase}?${qs.stringify(newQuery)}`)
      onChange && onChange(filterNames)
    } else {
      // remove all url queries
      const activeQueryKeys = Object.keys(router.query)
      router.push(asPathBase)
      onChange && onChange(activeQueryKeys)
    }
  }, [filterNames, onChange])

  return (
    <Wrapper alignBottom={alignBottom}>
      <Button variant="transparent" onClick={resetFilters}>
        <Label white={inverseVariant}>
          <Message id="filters.reset" />
        </Label>
      </Button>
      {!noIcon && (
        <Button
          icon="reset"
          variant="light"
          size="regular"
          onClick={resetFilters}
          data-cy="reset-filters-button"
          aria-label={formatMessage({ id: "filters.reset" })}
        ></Button>
      )}
    </Wrapper>
  )
}

FilterReset.toString = () => "FilterReset"
