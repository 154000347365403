import Head from "next/head"
import Link from "next/link"
import { ReactElement } from "react"
import { IErrorPage } from "src/services"
import { styled } from "src/stitches.config"

import { Button } from "../button"
import { Grid } from "../grid"
import { GridItem } from "../grid-item"
import { IconWrapper } from "../icon-wrapper"
import { PageWidthContainer } from "../page-width-container"
import { PublicLayout } from "../public-layout"
import {
  StyledPageContent,
  StyledPageHeader,
  StyledPageLead,
} from "../styled-page-content"
import { H1, P } from "../typography"

export interface ErrorProps {
  data: IErrorPage
}

const GoToLink = styled("div", {
  a: {
    color: "$blue",
    display: "inline-flex",
    alignItems: "center",

    span: {
      marginLeft: "0",
    },
  },

  "&:hover, &:focus": {
    svg: {
      opacity: 0.75,
    },
  },
})

const StyledButton = styled(Button, {
  width: "40px",
  height: "40px",
  marginLeft: "$20",
  svg: {
    width: "30px",
  },
})

export const Error = (props: ErrorProps): ReactElement => (
  <PublicLayout>
    <Head>
      <title>{`${props.data?.errorCode ? props.data.errorCode : ""} ${
        props.data?.title
      }`}</title>
    </Head>

    <PageWidthContainer>
      <StyledPageHeader>
        <H1 data-cy="err-code">{props.data?.title}</H1>
        <P size="extraLarge" data-cy="page-subtitle"></P>
      </StyledPageHeader>
    </PageWidthContainer>

    <PageWidthContainer>
      <Grid>
        <GridItem col={[2, 10]} medium={[1, 12]}>
          <StyledPageContent
            noPadding
            data-cy="page-content"
            css={{ "@mdUp": { marginBottom: "$40" } }}
          >
            <Grid columns={10}>
              <GridItem col={[2, 7]} small={[1, 10]}>
                <StyledPageLead>
                  <P size="large" bold data-cy="page-lead">
                    {props.data?.description}
                  </P>
                </StyledPageLead>
              </GridItem>
            </Grid>
          </StyledPageContent>

          {props.data?.goto &&
            props.data?.goto.map(({ text, href }, index) => {
              return (
                <GoToLink key={`${href}-${index}`}>
                  <Link href={href} passHref>
                    {text}
                    <StyledButton
                      variant="light"
                      size="large"
                      aria-label={text}
                    >
                      <IconWrapper name="arrowTailed" fill="#eb5e00" />
                    </StyledButton>
                  </Link>
                </GoToLink>
              )
            })}
        </GridItem>
      </Grid>
    </PageWidthContainer>
  </PublicLayout>
)
