import { ORGANIZATION_TYPES } from "@constants"
import { z } from "zod"

export enum CertificationProcessState {
  CREATED = "CREATED",
  WAITING_FOR_CERTIFICATION = "WAITING_FOR_CERTIFICATION",
  COMPLETED = "COMPLETED",
  CANCELED = "CANCELED",
}

export enum CertificationProcessReason {
  NEW = "NEW",
  RECERTIFICATION = "RECERTIFIFCATION", // note: there's a typo here, but renaming it would require a database migration
  RENOVATION = "RENOVATION",
  MIXED = "MIXED",
}
export const CertificationProcessReasonLabels = {
  [CertificationProcessReason.NEW]: "Nieuwbouw",
  [CertificationProcessReason.RECERTIFICATION]: "Hercertificering",
  [CertificationProcessReason.RENOVATION]: "Renovatie",
  [CertificationProcessReason.MIXED]: "Meerdere",
} as const
export const CertificationProcessReasonOptions = Object.entries(
  CertificationProcessReasonLabels
).map(([value, label]) => ({ value, label }))

export interface DisciplineSportClubs {
  id?: number
  name: string
  type: ORGANIZATION_TYPES.SPORTCLUBS
  kvkNumber?: string | null
  kvkEstablishmentNumber?: string | null
  city?: string | null
  address?: string | null
}

export interface IspDiscipline {
  code: string
  state: string
  sportClubs: DisciplineSportClubs[]
}

export interface ImplementedSportProduct {
  id: string
  accommodationId: string
  mainContractorId: number
  contractorId: number
  consultantId: number
  isRetired: boolean
  sportProduct: {
    id: string
    name: string
    sportProductType: {
      id: string
      name: string
    }
  }
  mainContractor?: {
    id: number
    name: string
  }
  contractor?: {
    id: number
    name: string
  }
  consultant?: {
    id: number
    name: string
  }
  certificationProcesses: {
    id: string
    state: CertificationProcessState
  }[]

  disciplines: IspDiscipline[]
}

export const implementedSportProductCreateSchema = z.object({
  sportProductId: z.string().uuid(),
  mainContractorId: z.number(),
  contractorId: z.number(),
  consultantId: z.number().optional(),
})

export type ImplementedSportProductCreate = z.infer<
  typeof implementedSportProductCreateSchema
>

export const implementedSportProductUpdateSchema = z.object({
  id: z.string().uuid(),
  sportProductId: z.string().uuid(),
  mainContractorId: z.number(),
  contractorId: z.number(),
  consultantId: z.number(),
})

export type ImplementedSportProductUpdate = z.infer<
  typeof implementedSportProductUpdateSchema
>
