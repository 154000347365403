import { useSession } from "next-auth/react"
import { useRouter } from "next/router"
import React, { useCallback } from "react"
import { useIntl } from "react-intl"

import { routes } from "../../../../routes"
import { InfrastructurePageHeaderInactiveComponent } from "../infrastructure-page-header-inactive-component"

interface PageHeaderNewProps {
  sportProductName: string
  id: string
  hideRestore?: boolean
}

export const PageHeaderNew = ({
  id,
  sportProductName,
  hideRestore,
}: PageHeaderNewProps) => {
  const { formatMessage } = useIntl()
  const router = useRouter()
  const { data: session } = useSession()

  const onClickHandler = useCallback(() => {
    router.push(routes["sports-products"].edit.route.replace(":id", String(id)))
  }, [])

  return (
    <InfrastructurePageHeaderInactiveComponent
      hideRestore={!session || !!hideRestore}
      isSecondary
      iconType="checkmark"
      title={formatMessage(
        { id: "sportProduct.header.new.title" },
        { name: sportProductName }
      )}
      subtitle={formatMessage({ id: "sportProduct.header.new.description" })}
      confirmButtonLabel={formatMessage({
        id: "sportProduct.header.new.button",
      })}
      onConfirm={onClickHandler}
    />
  )
}
