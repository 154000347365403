import { Fragment } from "react"
import { IImage } from "src/services"
import { styled } from "src/stitches.config"
import getAbsoluteSrc from "src/utils/get-absolute-src"

import { Grid } from "../grid"
import { GridItem } from "../grid-item"
import { H2, P } from "../typography"

interface Props {
  name: string
  description?: string
  contactName?: string
  // @TODO: Fix this type. Due to a change of the address in KVK.
  address?: any
  street?: string
  postalCode?: string
  city?: string
  country?: string
  phoneNumber?: string
  emailAddress?: string
  website?: string
  logo?: IImage | string
  showName: boolean
}

const OrganizationDetailsCardWrapper = styled("div", {
  position: "relative",
  marginBottom: "$40",
  backgroundColor: "#fff",
})

const OrganizationDetailsCardInner = styled("div", {
  paddingTop: "$40",
  paddingBottom: "$20",

  "@lgDown": {
    padding: "$20",
  },

  variants: {
    hasLogo: {
      true: {
        "@lgDown": {
          [H2.toString()]: {
            paddingRight: "150px",
          },
        },
        "@mdDown": {
          [H2.toString()]: {
            paddingRight: "100px",
          },
        },
      },
    },
  },
})

const OrganizationDetailsCardLogoWrapper = styled("div", {
  position: "absolute",
  top: "0",
  right: "0",
  backgroundColor: "$white",
  boxShadow: "$default",

  "@mdDown": {
    width: "100px",
    height: "100px",
  },

  "@mdUp": {
    width: "150px",
    height: "150px",
  },

  "@lgUp": {
    transform: "translateX(50%)",
  },

  img: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
})

function renderOptionalAddressLine(line?: string) {
  if (!line || line.trim() === "") {
    return null
  }

  return (
    <Fragment>
      {line}
      <br />
    </Fragment>
  )
}

function renderOptionalContactLink(
  type: "tel" | "mailto" | "web",
  link?: string
) {
  if (!link) {
    return null
  }

  return (
    <Fragment>
      {type === "tel" && <a href={`tel:${link}`}>{link}</a>}
      {type === "mailto" && <a href={`mailto:${link}`}>{link}</a>}
      {type === "web" && (
        <a href={link} rel="nofollow noreferrer">
          {link}
        </a>
      )}
      <br />
    </Fragment>
  )
}

export const OrganizationDetailsCard = (props: Props) => (
  <OrganizationDetailsCardWrapper>
    <Grid columns={10}>
      <GridItem col={[3, 7]} medium={[1, 10]}>
        <OrganizationDetailsCardInner hasLogo={!!props.logo}>
          {props.showName ? <H2>{props.name}</H2> : null}

          {(props.description ||
            props.contactName ||
            props.street ||
            props.postalCode ||
            props.city) && (
            <P extraLineHeight>
              {props.description && (
                <strong>{renderOptionalAddressLine(props.description)}</strong>
              )}
              {renderOptionalAddressLine(props.contactName)}
              {renderOptionalAddressLine(props.address || props.street)}
              {renderOptionalAddressLine(
                [props.postalCode, props.city].join(" ")
              )}
              {renderOptionalAddressLine(props.country)}
            </P>
          )}

          {(props.phoneNumber || props.emailAddress || props.website) && (
            <P extraLineHeight coloredAnchors>
              {renderOptionalContactLink("tel", props.phoneNumber)}
              {renderOptionalContactLink("mailto", props.emailAddress)}
              {renderOptionalContactLink("web", props.website)}
            </P>
          )}
        </OrganizationDetailsCardInner>
      </GridItem>
    </Grid>

    {!!props.logo && (
      <OrganizationDetailsCardLogoWrapper>
        <img
          src={getAbsoluteSrc(
            typeof props.logo === "string" ? props.logo : props.logo.url
          )}
          alt={props.name}
        />
      </OrganizationDetailsCardLogoWrapper>
    )}
  </OrganizationDetailsCardWrapper>
)
