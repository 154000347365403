import { ReactText, useMemo } from "react"
import { DATE_FORMAT } from "src/constants"
import { styled } from "src/stitches.config"
import formatDate from "src/utils/format-date"

import { BackLink } from "../black-link"
import { DocumentVersionList } from "../document-version-list"
import { Grid } from "../grid"
import { GridItem } from "../grid-item"
import { Message } from "../message"
import { H1, H2, H4 } from "../typography"

export type THandbookVersion = {
  code: string
  title: string
  isLatest: boolean
  isDraft: boolean
  isActive: boolean
  updatedAt: string
  location: string
  category: string
  version: ReactText
}

export interface IDocumentArchiveProps {
  title: string
  code: ReactText
  versions: THandbookVersion[]
}

const Container = styled("div", {
  padding: "$40 0 $80",
})

export const DocumentArchive = ({
  title,
  code,
  versions,
}: IDocumentArchiveProps) => {
  const parsedVersions = useMemo(
    () =>
      versions.map((v) => {
        const tail = v.isLatest ? "" : `/archief/${v.version}`
        return {
          date: formatDate(v.updatedAt, DATE_FORMAT),
          url: `/kwaliteitszorgsysteem/voorschriften/${v.code}${tail}`,
        }
      }),
    [versions]
  )

  return (
    <Container>
      <BackLink
        title={<Message id="infrastructureDetails.archive.backButton.title" />}
        route={`/kwaliteitszorgsysteem/voorschriften/${code}`}
      />
      <Grid>
        <GridItem col={[1, 2]} small={[1, 12]}>
          <H2>
            <Message id="archive.title" />
          </H2>
          <H4 css={{ marginBottom: "$20", color: "$blueHighlight" }}>{code}</H4>
        </GridItem>
        <GridItem col={[3, 12]} small={[1, 12]}>
          <H1>{title}</H1>

          <DocumentVersionList versions={parsedVersions} />
        </GridItem>
      </Grid>
    </Container>
  )
}
