import { useRouter } from "next/router"
import React, { FormEventHandler, Fragment, useCallback, useMemo } from "react"
import { UseFormReturn } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { Button } from "src/components/button"
import { HorizontalFlex } from "src/components/flex"
import { Toggle } from "src/components/toggle"
import { ORGANIZATION_TYPES } from "src/constants"
import { useAdminContext } from "src/context/admin-context"
import { IOrganization } from "src/services"
import api from "src/services/api"
import { styled } from "src/stitches.config"
import alphabeticalSort from "src/utils/alphabetical-sort"
import { transformers } from "src/utils/mappers"

import { ContentBox } from "../../context-box"
import { Option, WrappedDropdownSelect } from "../../dropdown-select"
import { Checkbox, ConfirmationButton } from "../../edit-forms"
import {
  Form,
  FormInput,
  FormItem,
  FormItemInput,
  FormItemLabel,
  UploadInput,
} from "../../forms"
import { Grid } from "../../grid"
import { GridItem } from "../../grid-item"
import { WrappedMultiselectDropdown } from "../../multiselect-dropdown"
import { OrganizationSelector } from "../../organization-selector"
import { P as GenericP, H1, H3, H5 } from "../../typography"

interface Props {
  onSubmit: FormEventHandler<HTMLFormElement>
  form: UseFormReturn<any>
  mode?: "create" | "update"
  id?: number
}

const P = styled(GenericP, {
  margin: "0",
})

const DropdownSpacer = styled("div", {
  height: "200px",
})

const UseKVKMessage = styled("div", {
  position: "absolute",
  inset: "0",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingBottom: "5%",
  backgroundColor: "rgba(255, 255, 255, 0.95)",
  zIndex: "1",
})

const UseKVKMessageInner = styled("div", {
  width: "100%",
  maxWidth: "480px",
})

export const OrganizationForm = ({ onSubmit, form, mode, id }: Props) => {
  const { formatMessage } = useIntl()
  const { watch, setValue } = form
  const { users } = useAdminContext()

  const values = watch()
  const type = watch("type")?.value as ORGANIZATION_TYPES
  const name = watch("name")
  const isForeign = watch("isForeign")
  const router = useRouter()

  const showKVKSearch = !name

  const selectOptions = useMemo(() => {
    return {
      types: Object.values(ORGANIZATION_TYPES).map(transformers.organizationType), // prettier-ignore
      users: users.map(transformers.users).sort(alphabeticalSort),
    }
  }, [])

  const showDescriptionField = ORGANIZATION_TYPES.INSTITUTES === type

  const isFieldDisabled = useCallback((fieldName: string) => {
    return !!form.control._defaultValues[fieldName]
  }, [])

  function resetFields() {
    setValue("isForeign", false)
    setValue("name", "")
    setValue("address", "")
    setValue("postalCode", "")
    setValue("city", "")
    setValue("kvkNumber", "")
    setValue("kvkEstablishmentNumber", "")
  }

  function handleOrganizationChange(organization?: IOrganization) {
    if (organization) {
      setValue("name", organization.name)
      setValue("address", "")
      setValue("city", organization.city)
      setValue("kvkNumber", organization.kvkNumber)
      setValue("kvkEstablishmentNumber", organization.kvkEstablishmentNumber)
    } else {
      resetFields()
    }
  }

  function handleTypeChange(value: Option) {
    setValue("type", value.value)

    if (value.value === ORGANIZATION_TYPES.SPORTCLUBS) {
      resetFields()
    }
  }

  function handleAddForeignClick(event: React.MouseEvent<HTMLAnchorElement>) {
    event.preventDefault()
    setValue("isForeign", true)
  }

  function handleIsForeignChange() {
    setValue("isForeign", !isForeign)
    resetFields()
  }

  async function handleDeactivateClick() {
    if (!id) return false
    return await api.admin.updateOrganizationStatus(id, false)
  }

  async function handleDeactivateSuccess() {
    router.push(`/organisaties/${id}`)
  }

  return (
    <Form onSubmit={onSubmit} form={form}>
      <Grid>
        <GridItem col={[1, 12]}>
          <H1
            data-cy="page-title"
            css={{ marginBottom: "$60", display: "flex" }}
          >
            {formatMessage({ id: `${mode}.organization.title` })}
            {mode === "update" && (
              <ConfirmationButton
                messageTitle={name}
                onClick={handleDeactivateClick}
                onSuccess={handleDeactivateSuccess}
                confirmationMessage={formatMessage(
                  { id: "admin.deactivateDoc.confirmationArchiveText" },
                  { code: name }
                )}
                confirmationButtonLabel={formatMessage({
                  id: "admin.deactivateDoc.confirmationArchiveButton",
                })}
                buttonIcon="archive"
                buttonVariant="darkGreen"
              />
            )}
          </H1>
        </GridItem>

        <GridItem col={[1, 3]} medium={[1, 12]}>
          <H3 css={{ "@mdUp": { textAlign: "right" } }} noMargin>
            <FormattedMessage id="edit.organization.type.title" />
          </H3>
        </GridItem>

        <GridItem col={[4, 8]} medium={[1, 12]}>
          <ContentBox>
            <FormItem horizontal>
              <FormItemInput data-cy="form-input-types" css={{ "@mdUp": {} }}>
                <WrappedDropdownSelect
                  name="type"
                  options={selectOptions.types}
                  onChange={handleTypeChange}
                  placeholder={formatMessage({
                    id: "edit.organization.type.placeholder",
                  })}
                  noDefault={!type}
                />
              </FormItemInput>
            </FormItem>

            {showDescriptionField && (
              <FormItem horizontal>
                <FormItemLabel htmlFor="description">
                  <FormattedMessage id="edit.organization.description.label" />
                </FormItemLabel>
                <FormItemInput>
                  <FormInput
                    data-cy="form-input-type-description"
                    displayAs={P}
                    name="description"
                    placeholder={formatMessage({
                      id: "edit.organization.description.placeholder",
                    })}
                    trimLineBreaks
                    italic
                  />
                </FormItemInput>
              </FormItem>
            )}
          </ContentBox>
        </GridItem>
      </Grid>

      <Grid>
        <GridItem col={[1, 3]} medium={[1, 12]}>
          <H3 css={{ "@mdUp": { textAlign: "right" } }} noMargin>
            <FormattedMessage id="edit.organization.base.title" />
          </H3>
        </GridItem>

        <GridItem col={[4, 8]} medium={[1, 12]}>
          <ContentBox css={{ position: "relative" }}>
            {mode === "create" && !showKVKSearch && !isForeign && (
              <OrganizationSelector
                onChange={handleOrganizationChange}
                value={values}
                disableMunicipalitySelect
              />
            )}

            {mode === "create" && !isForeign && showKVKSearch && (
              <UseKVKMessage>
                <UseKVKMessageInner>
                  <H5 css={{ marginBottom: "$5" }}>KVK zoekmachine</H5>
                  <P css={{ marginBottom: "$10" }} size="small">
                    Om de volledigheid van de gegevens te kunnen garanderen is
                    het bij het toevoegen van organisaties verplicht de KVK
                    zoekmachine te gebruiken.
                  </P>

                  <OrganizationSelector
                    onChange={handleOrganizationChange}
                    disableMunicipalitySelect
                  />

                  <Button
                    variant="secondary"
                    onClick={handleAddForeignClick}
                    data-cy="foreign-organization-button"
                  >
                    Voeg buitenlandse organisatie toe
                  </Button>
                </UseKVKMessageInner>
              </UseKVKMessage>
            )}

            {isForeign && (
              <FormItem horizontal>
                <HorizontalFlex alignItems="center">
                  <Toggle
                    small
                    positive
                    data-cy="form-input-isForeign"
                    onChange={handleIsForeignChange}
                    checked={!!isForeign}
                    disabled={mode === "update"}
                  />
                  <P css={{ marginLeft: "$10", paddingBottom: ".2rem" }}>
                    Organisatie bevindt zich in het buitenland
                  </P>
                </HorizontalFlex>
              </FormItem>
            )}

            <FormItem horizontal>
              <FormItemLabel htmlFor="name">
                <FormattedMessage id="edit.organization.name.label" />
              </FormItemLabel>
              <FormItemInput>
                <FormInput
                  data-cy="form-input-name"
                  displayAs={P}
                  name="name"
                  placeholder=""
                  trimLineBreaks
                  italic
                  isDisabled={!isForeign}
                />
              </FormItemInput>
            </FormItem>

            <FormItem horizontal>
              <FormItemLabel htmlFor="address">
                <FormattedMessage id="edit.organization.address.label" />
              </FormItemLabel>
              <FormItemInput>
                <FormInput
                  data-cy="form-input-address"
                  displayAs={P}
                  name="address"
                  placeholder={formatMessage({
                    id: "edit.organization.address.placeholder",
                  })}
                  trimLineBreaks
                  italic
                  isDisabled={!isForeign && showKVKSearch}
                />
              </FormItemInput>
            </FormItem>

            <FormItem horizontal>
              <FormItemLabel htmlFor="postalCode">
                <FormattedMessage id="edit.organization.postalCode.label" />
              </FormItemLabel>
              <FormItemInput css={{ "@mdUp": { maxWidth: "150px" } }}>
                <FormInput
                  data-cy="form-input-postalcode"
                  displayAs={P}
                  name="postalCode"
                  placeholder={
                    !isForeign
                      ? formatMessage({
                          id: "edit.organization.postalCode.placeholder",
                        })
                      : formatMessage({
                          id: "edit.organization.postalCode.label",
                        })
                  }
                  trimLineBreaks
                  italic
                  isDisabled={!isForeign && showKVKSearch}
                />
              </FormItemInput>
            </FormItem>

            <FormItem horizontal>
              <FormItemLabel htmlFor="city">
                <FormattedMessage id="edit.organization.city.label" />
              </FormItemLabel>
              <FormItemInput>
                <FormInput
                  data-cy="form-input-city"
                  displayAs={P}
                  name="city"
                  placeholder=""
                  trimLineBreaks
                  italic
                  isDisabled={!isForeign && showKVKSearch}
                />
              </FormItemInput>
            </FormItem>

            <FormItem horizontal>
              <FormItemLabel htmlFor="country">
                <FormattedMessage id="edit.organization.country.label" />
              </FormItemLabel>
              <FormItemInput>
                <FormInput
                  data-cy="form-input-country"
                  displayAs={P}
                  name="country"
                  placeholder=""
                  trimLineBreaks
                  italic
                  isDisabled={!isForeign && showKVKSearch}
                />
              </FormItemInput>
            </FormItem>

            {!isForeign && (
              <Fragment>
                <FormItem horizontal>
                  <FormItemLabel htmlFor="kvkNumber">
                    <FormattedMessage id="edit.organization.kvkNumber.label" />
                  </FormItemLabel>
                  <FormItemInput>
                    <FormInput
                      data-cy="form-input-kvk"
                      displayAs={P}
                      name="kvkNumber"
                      placeholder=""
                      trimLineBreaks
                      isDisabled={isFieldDisabled("kvkNumber")}
                      italic
                    />
                  </FormItemInput>
                </FormItem>

                <FormItem horizontal>
                  <FormItemLabel htmlFor="kvkEstablishmentNumber">
                    <FormattedMessage id="edit.organization.kvkEstablishmentNumber.label" />
                  </FormItemLabel>
                  <FormItemInput>
                    <FormInput
                      data-cy="form-input-kvk"
                      displayAs={P}
                      name="kvkEstablishmentNumber"
                      placeholder=""
                      trimLineBreaks
                      isDisabled={isFieldDisabled("kvkEstablishmentNumber")}
                      italic
                    />
                  </FormItemInput>
                </FormItem>
              </Fragment>
            )}
          </ContentBox>
        </GridItem>
      </Grid>

      <Grid>
        <GridItem col={[1, 3]} medium={[1, 12]}>
          <H3 css={{ "@mdUp": { textAlign: "right" } }} noMargin>
            <FormattedMessage id="edit.organization.contact.title" />
          </H3>

          <P css={{ "@mdUp": { textAlign: "right" } }} size="large" light>
            <FormattedMessage id="edit.organization.contact.subtitle" />
          </P>
        </GridItem>

        <GridItem col={[4, 8]} medium={[1, 12]}>
          <ContentBox>
            <FormItem horizontal>
              <FormItemLabel htmlFor="emailAddress">
                <FormattedMessage id="edit.organization.emailAddress.label" />
              </FormItemLabel>
              <FormItemInput>
                <FormInput
                  data-cy="form-input-email"
                  displayAs={P}
                  name="emailAddress"
                  placeholder={formatMessage({
                    id: "edit.organization.emailAddress.placeholder",
                  })}
                  trimLineBreaks
                  italic
                />
              </FormItemInput>
            </FormItem>

            <FormItem horizontal>
              <FormItemLabel htmlFor="phoneNumber">
                <FormattedMessage id="edit.organization.phoneNumber.label" />
              </FormItemLabel>
              <FormItemInput>
                <FormInput
                  data-cy="form-input-phone"
                  displayAs={P}
                  name="phoneNumber"
                  placeholder={formatMessage({
                    id: "edit.organization.phoneNumber.placeholder",
                  })}
                  trimLineBreaks
                  italic
                />
              </FormItemInput>
            </FormItem>

            <FormItem horizontal>
              <FormItemLabel htmlFor="website">
                <FormattedMessage id="edit.organization.website.label" />
              </FormItemLabel>
              <FormItemInput>
                <FormInput
                  data-cy="form-input-website"
                  displayAs={P}
                  name="website"
                  placeholder={formatMessage({
                    id: "edit.organization.website.placeholder",
                  })}
                  trimLineBreaks
                  italic
                />
              </FormItemInput>
            </FormItem>

            <FormItem horizontal>
              <FormItemLabel htmlFor="logo">
                <FormattedMessage id="edit.organization.logo.label" />
              </FormItemLabel>
              <FormItemInput data-cy="form-input-upload">
                <UploadInput
                  name="logo"
                  endpoint="/upload/organizations"
                  buttonLabel="Upload logo"
                  acceptTypes={"image/*"}
                  placeholder={formatMessage({
                    id: "edit.organization.logo.placeholder",
                  })}
                />
              </FormItemInput>
            </FormItem>

            <FormItem horizontal>
              <FormItemLabel htmlFor="users">
                <FormattedMessage id="form.organization.users.label" />
              </FormItemLabel>
              <FormItemInput data-cy="form-input-users">
                <WrappedMultiselectDropdown
                  twoLines={true}
                  minWidth="medium"
                  name="users"
                  options={selectOptions.users}
                />
              </FormItemInput>
            </FormItem>
          </ContentBox>
        </GridItem>
      </Grid>

      {/* Pushing down a bit to make room for the Multiselect dropdown */}
      <DropdownSpacer />
    </Form>
  )
}
