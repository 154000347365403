import { Row } from "react-table"
import { styled } from "src/stitches.config"

import { P } from "../../typography"

const StyledTR = styled("tr", {
  position: "relative",
})

const StyledTD = styled("td", {
  a: {
    color: "inherit",

    "&:hover": {
      color: "inherit",
    },

    "&::after": {
      position: "absolute",
      top: "0",
      right: "0",
      bottom: "0",
      left: "0",
      content: "",
    },
  },
})

type Props = {
  row: Row & { isArchived?: boolean }
  onClick?: (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void
}

export const TRow: React.FC<Props> = ({ row, onClick }) => {
  const handleClick = (
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>
  ) => {
    if (onClick) {
      onClick(e)
    }
  }

  return (
    <StyledTR
      {...row.getRowProps()}
      className={row?.isArchived ? "highlight" : ""}
      onClick={handleClick}
    >
      {row.cells.map((cell) => (
        <StyledTD
          {...cell.getCellProps()}
          key={`${cell.column.id}${cell.row.id}`}
        >
          <P size="small" noMargin>
            {cell.render("Cell")}
          </P>
        </StyledTD>
      ))}
    </StyledTR>
  )
}
