import { useMutation, useQueryClient } from "@tanstack/react-query"
import { toast } from "react-hot-toast"
import { notesService } from "src/services"

export function useCertificationProcessDeleteNote(
  id: string,
  certificationProcessId: string
) {
  const queryClient = useQueryClient()

  function mutateFn() {
    return notesService.delete(id)
  }

  return useMutation(mutateFn, {
    onSuccess: () => {
      toast.success("Notitie verwijderd")
      queryClient.refetchQueries([
        "certification-process",
        certificationProcessId,
        "notes",
      ])
    },
    onError: (error) => {
      toast.error(
        "Verwijderen notitie uit certificering niet gelukt. Probeer het nog een keer of neem contact met ons op."
      )
    },
  })
}
