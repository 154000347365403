import {
  UseQueryOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query"
import { toast } from "react-hot-toast"
import { implementedSportProductService } from "src/services"
import { ImplementedSportProductUpdate } from "src/services/v2/implemented-sport-product"

export function useUpdateImplementedSportProduct(
  accommodationId: string,
  options?: UseQueryOptions
) {
  const queryClient = useQueryClient()
  const key = ["accommodation", accommodationId, "implemented-sport-products"]

  async function mutateFn(data: ImplementedSportProductUpdate) {
    const response = await implementedSportProductService.update(data.id, data)
    await queryClient.refetchQueries(key)
    return response
  }

  return useMutation(mutateFn, {
    onSuccess: (...args) => {
      toast.success("Sportproduct geupdate")
      options?.onSuccess?.(args)
    },
  })
}
