import {
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from "@tanstack/react-query"
import { certificationProcessService } from "src/services"

import { CertificationProcessListItems } from "../types"

export function useCertificationProcesses(
  options?: UseQueryOptions
): UseQueryResult<CertificationProcessListItems> {
  const queryFn = async () => {
    const data = await certificationProcessService.getAll()

    return data
  }

  return useQuery(["certification-processes"], queryFn, {
    initialData: options?.initialData,
  })
}
