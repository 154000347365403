import { useRouter } from "next/router"
import { UseTableOptions, useFilters, useSortBy, useTable } from "react-table"

import { TBody, THSorter, THead, TRow, Table } from "./fragments"

interface DataTableProps extends UseTableOptions<{ [p: string]: any }> {
  linkRoutes?: [string, string] // ['projects.edit.route', 'id']
  enableFilterHeader?: boolean
}

export const DataTable = ({
  columns,
  data,
  linkRoutes,
  enableFilterHeader = true,
}: DataTableProps) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useFilters, useSortBy)
  const router = useRouter()

  const clickHandler = (
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    newRoute: string
  ) => {
    if (e.metaKey || e.ctrlKey) {
      window.open(newRoute, "_blank")
    } else {
      router.push(newRoute)
    }
  }

  return (
    <Table {...getTableProps()}>
      <THead>
        {headerGroups.map((headerGroup, index) => (
          <tr {...headerGroup.getHeaderGroupProps()} key={index}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                key={column.id}
              >
                <span>{column.render("Header")}</span>
                <THSorter isSorted={column.isSorted}>
                  {column.isSorted ? (column.isSortedDesc ? "↓" : "↑") : "↕"}
                </THSorter>
              </th>
            ))}
          </tr>
        ))}
      </THead>
      {enableFilterHeader && (
        <THead data-cy="filter-header">
          {headerGroups.map((headerGroup, index) => (
            <tr key={index}>
              {headerGroup.headers.map((column) => (
                <th key={column.id}>
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </THead>
      )}

      <TBody data-cy="table-body" {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row)
          return linkRoutes ? (
            <TRow
              row={row}
              onClick={(e) =>
                clickHandler(
                  e,
                  linkRoutes[0].replace(":id", row.original[linkRoutes[1]])
                )
              }
            />
          ) : (
            <TRow row={row} key={row.id} />
          )
        })}
      </TBody>
    </Table>
  )
}
