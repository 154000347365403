import Head from "next/head"
import Link from "next/link"
import { ReactElement } from "react"
import { useIntl } from "react-intl"
import {
  Button,
  Grid,
  GridItem,
  H1,
  IconWrapper,
  P,
  PageWidthContainer,
  PublicLayout,
  StyledPageContent,
  StyledPageHeader,
  StyledPageLead,
} from "src/components"
import { styled } from "src/stitches.config"
import { getTitleForStatusCode } from "src/utils/get-title-for-status-code"

interface ErrorPageProps {
  statusCode: number
  errorMessage: string
}

const GoToLink = styled("div", {
  a: {
    color: "$blue",
    display: "inline-flex",
    alignItems: "center",

    span: {
      marginLeft: "0",
    },

    [Button.toString()]: {
      width: "40px",
      height: "40px",
      marginLeft: "$20",
      svg: {
        width: "30px",
      },
    },
  },

  "&:hover, &:focus": {
    svg: {
      opacity: 0.75,
    },
  },
})

export default function Error(props: ErrorPageProps): ReactElement {
  const { statusCode, errorMessage } = props
  const { formatMessage } = useIntl()

  const title = formatMessage({
    id: `errorPage.${statusCode}.title`,
    defaultMessage: getTitleForStatusCode(statusCode),
  })

  const message = formatMessage({
    id: `errorPage.${statusCode}.message`,
    defaultMessage: JSON.stringify(errorMessage, null, 2),
  })

  return (
    <PublicLayout>
      <Head>
        <title>
          {statusCode} - {title}
        </title>
      </Head>

      <PageWidthContainer>
        <StyledPageHeader>
          <H1 data-cy="err-code">{title}</H1>
        </StyledPageHeader>
      </PageWidthContainer>

      <PageWidthContainer>
        <Grid>
          <GridItem col={[2, 10]} medium={[1, 12]}>
            <StyledPageContent
              noPadding
              data-cy="page-content"
              css={{ "@mdUp": { marginBottom: "$40" } }}
            >
              <Grid columns={10}>
                <GridItem col={[2, 7]} small={[1, 10]}>
                  <StyledPageLead>
                    <P size="large" bold data-cy="page-lead">
                      {message}
                    </P>
                  </StyledPageLead>
                </GridItem>
              </Grid>
            </StyledPageContent>

            <GoToLink>
              <Link href="/" passHref>
                Ga naar de homepage
                <Button
                  variant="light"
                  size="large"
                  aria-label="Ga naar de homepage"
                >
                  <IconWrapper name="arrowTailed" fill="#eb5e00" />
                </Button>
              </Link>
            </GoToLink>
          </GridItem>
        </Grid>
      </PageWidthContainer>
    </PublicLayout>
  )
}
