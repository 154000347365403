import React, { PropsWithChildren, ReactElement } from "react"
import { styled } from "src/stitches.config"

interface GridItemProps {
  col: number[]
  row?: string
  rowSmall?: string
  rowMedium?: string
  small?: number[]
  medium?: number[]
}

const StyledGridItem = styled("div", {})

export const GridItem = (
  props: PropsWithChildren<GridItemProps>
): ReactElement => {
  const { col, row, medium, small, rowSmall, rowMedium, children, ...rest } =
    props
  const css = {
    gridColumn: `${col[0]} / span ${col[1]}`,
    ...(!!row && {
      gridRow: row,
    }),

    ...(!!medium && {
      "@lgDown": {
        gridColumn: `${medium[0]} / span ${medium[1]}`,
        ...(!!rowMedium && {
          gridRow: rowMedium,
        }),
      },
    }),

    ...(!!small && {
      "@mdDown": {
        gridColumn: `${small[0]} / span ${small[1]}`,
        ...(!!rowSmall && {
          gridRow: rowSmall,
        }),
      },
    }),
  }

  return (
    <StyledGridItem css={css} {...rest}>
      {children}
    </StyledGridItem>
  )
}
