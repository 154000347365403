import Link from "next/link"
import React, { ReactElement } from "react"
import { IOrganizationListItem } from "src/services"
import { styled } from "src/stitches.config"

import { IconWrapper } from "../icon-wrapper"
import { P } from "../typography"

const IconWrapperStyled = styled("span", {
  transition: ".2s transform",
})

const CardWrapper = styled("div", {
  position: "relative",
  display: "flex",
  height: "100%",
  padding: "$20",
  borderRadius: "4px",
  backgroundColor: "$white",
  boxShadow: "$default",
  transition: ".2s transform",
  marginBottom: "$20",

  "&:hover": {
    transform: "translateY(-3px)",

    a: {
      textDecoration: "none",
    },

    [`${IconWrapper}`]: {
      transform: "translateX(4px)",
    },
  },

  variants: {
    isDisabled: {
      true: {
        background: "linear-gradient(to right, $white, $grayLight)",
        p: {
          color: "$disabled",
        },
      },
    },
  },
})

const LinkWrapper = styled("a", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  textDecoration: "none",
})

export const OrganizationListItem = (
  props: IOrganizationListItem
): ReactElement => (
  <CardWrapper
    data-cy="organization-list-item"
    data-cy-value={props.disabled ? "disabled-item" : "active-item"}
    isDisabled={props.disabled}
  >
    <Link href={props.href} passHref legacyBehavior>
      <LinkWrapper>
        <div>
          <P data-cy="organization-list-item-title" bold noMargin size="large">
            {props.title}
          </P>

          <P data-cy="organization-list-item-description" noMargin>
            {props.description}
          </P>
        </div>

        <IconWrapperStyled>
          <IconWrapper name="arrowRight" disabled={props.disabled} />
        </IconWrapperStyled>
      </LinkWrapper>
    </Link>
  </CardWrapper>
)
