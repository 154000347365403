import { useQuery, useQueryClient } from "@tanstack/react-query"
import { IDropdownOption } from "@types"
import axios from "axios"
import axiosRetry from "axios-retry"
import { useIntl } from "react-intl"
import { KVK_API_RATE_LIMIT_INTERVAL } from "src/constants"
import { transformers } from "src/utils/mappers"

const CACHE_KEY = "kvkSearchResults"

export type TKVKData = IDropdownOption[]

interface ReturnProps {
  filter: (_: any, query: string) => Promise<TKVKData>
}

axiosRetry(axios, {
  retries: 1,
  retryDelay: () => {
    return KVK_API_RATE_LIMIT_INTERVAL
  },
  retryCondition: (error) => {
    return error?.response?.status === 429
  },
})

export default function useKVKSearch(): ReturnProps {
  const queryClient = useQueryClient()
  const { formatMessage } = useIntl()
  const { data: recentResults = [] } = useQuery(
    [CACHE_KEY, "recent"],
    () => {},
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  )

  function storeDataAsRecent(data: TKVKData) {
    queryClient.setQueryData([CACHE_KEY, "recent"], data)
  }

  async function filter(_: any, query: string) {
    // In case we arent searching yet, return the most recent results
    if (query.length < 3) {
      return recentResults
    }

    // Check if we have some results in the cache already. And if so: return those
    const cachedData: TKVKData | undefined = queryClient.getQueryData([
      CACHE_KEY,
      query,
    ])
    if (cachedData) {
      storeDataAsRecent(cachedData)
      return cachedData
    }

    try {
      // Fetching with Axios here, then manually adding the results to the react-query cache
      const { status, data } = await axios("/api/kvk/search", {
        method: "post",
        data: { query },
      })

      if (data && status === 200) {
        const mappedData: TKVKData = data.resultaten.map(transformers.kvkResult)
        queryClient.setQueryData([CACHE_KEY, query], mappedData)
        storeDataAsRecent(mappedData)
        return mappedData
      }
    } catch (error) {
      if (
        error &&
        axios.isAxiosError(error) &&
        error.response?.status !== 404
      ) {
        const label = formatMessage({
          id: "Verenigingen niet beschikbaar, probeer later opnieuw",
        })
        return [{ id: "1", value: "error", label, main: true, disabled: true }]
      }
    }

    // Fallback to returning an empty array
    return []
  }

  return {
    filter,
  }
}
