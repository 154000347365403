import { useRouter } from "next/router"
import React, { FormEventHandler, useCallback, useEffect, useMemo } from "react"
import { useFieldArray } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { useAdminContext } from "src/context/admin-context"
import api from "src/services/api"
import { styled } from "src/stitches.config"
import alphabeticalSort from "src/utils/alphabetical-sort"
import { transformers } from "src/utils/mappers"

import { routes } from "../../../../routes"
import { Button } from "../../button"
import { ContentBox } from "../../context-box"
import { WrappedDropdownSelect } from "../../dropdown-select"
import { ConfirmationButton } from "../../edit-forms"
import {
  Form,
  FormInput,
  FormItem,
  FormItemInput,
  FormItemLabel,
} from "../../forms"
import { Grid } from "../../grid"
import { GridItem } from "../../grid-item"
import { Message } from "../../message"
import { WrappedMultiselectDropdown } from "../../multiselect-dropdown"
import { H1, H3, P } from "../../typography"
import { LinkFormItem, ReportFormItem } from "./fragments"

interface Props {
  onSubmit: FormEventHandler<HTMLFormElement>
  form: any
  mode?: "create" | "update"
  id?: string
  systemStatus?: string
}

const motionProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
}

const ArchiveWrapper = styled(P, {
  display: "flex",
})

export const SportsProductForm = ({
  onSubmit,
  form,
  mode,
  id,
  systemStatus,
}: Props) => {
  const {
    contractors,
    disciplines,
    locations,
    sportProductProductStatuses,
    sportProductSubtypes: allSportProductSubtypes,
    sportProductSystemStatuses,
    sportProductTypes,
    manufacturers,
    sustainabilityLabels,
    institutes,
  } = useAdminContext()
  const { formatMessage } = useIntl()
  const router = useRouter()
  const { watch, setValue } = form
  const name = watch("name")
  const type = watch("type")
  const subtype = watch("subtype")

  const normLinks = useFieldArray({
    name: "normLinks",
    control: form.control,
    keyName: "_id",
  })

  const reports = useFieldArray({
    name: "reports",
    control: form.control,
    keyName: "_id",
  })

  const sportProductSubtypes = useMemo(() => {
    if (!type) return []

    return allSportProductSubtypes.filter(({ relatedTypeId }) => {
      return relatedTypeId === type.id
    })
  }, [allSportProductSubtypes, type])

  /**
   * Reset the `subtype` value when the combination of `type` and
   * `subtype` should not be possible.
   */
  useEffect(() => {
    // find the full object (that includes the `relatedTypeId`)
    const fullSubtype = sportProductSubtypes.find(({ id }) => {
      return subtype?.id === id
    })

    // compare and reset when needed
    if (type?.id !== fullSubtype?.relatedTypeId) {
      setValue("subtype", undefined)
    }
  }, [type, subtype, sportProductSubtypes])

  const mappedOptions = useMemo(() => {
    return {
      manufacturers: manufacturers
        .map(transformers.manufacturer)
        .sort(alphabeticalSort),
      contractors: contractors
        .map(transformers.contractor)
        .sort(alphabeticalSort),
      institutes: institutes.map(transformers.institute).sort(alphabeticalSort),
      disciplines: disciplines
        .map(transformers.discipline)
        .sort(alphabeticalSort),
      locations: locations.map(transformers.location).sort(alphabeticalSort),
      sportProductTypes: sportProductTypes
        .map(transformers.sportProductType)
        .sort(alphabeticalSort),
      sportProductSubtypes: sportProductSubtypes
        .map(transformers.sportProductSubtype)
        .sort(alphabeticalSort),
      productStatuses: sportProductProductStatuses
        .map(transformers.productStatus)
        .sort(alphabeticalSort),
      systemStatuses: sportProductSystemStatuses
        .map(transformers.systemStatus)
        .sort(alphabeticalSort),
      sustainabilityLabels: sustainabilityLabels
        .map(transformers.sustainabilityLabel)
        .sort(alphabeticalSort),
    }
  }, [sportProductSubtypes])

  function handleAddLinkClick() {
    normLinks.append({ text: null, url: null })
  }

  function handleAddReportClick() {
    reports.append({ title: "", url: null })
  }

  const handleOnDeactivate = useCallback(async () => {
    return (
      id &&
      (await api.admin.updateSportsProductStatusById(id, { active: false }))
    )
  }, [id])

  const onDeactivateSuccess = useCallback(() => {
    router.push(routes["sports-products"].get.route.replace(":id", String(id)))
  }, [id])

  const handleOnDelete = useCallback(async () => {
    return (
      id &&
      (await api.admin.updateSportsProductStatusById(id, { deleted: true }))
    )
  }, [id])

  const onDeleteSuccess = useCallback(
    () => router.push(routes["sports-products"].list.route),
    []
  )

  return (
    <Form onSubmit={onSubmit} form={form}>
      <Grid>
        {mode === "update" && (
          <GridItem col={[1, 11]} medium={[1, 12]}>
            <ArchiveWrapper bold>
              {formatMessage({ id: "admin.sportsProducts.archive.title" })}
              {systemStatus !== "new" && (
                <ConfirmationButton
                  messageTitle={name}
                  onClick={handleOnDeactivate}
                  onSuccess={onDeactivateSuccess}
                  confirmationMessage={formatMessage(
                    { id: "admin.deactivateDoc.confirmationArchiveText" },
                    { code: name }
                  )}
                  confirmationButtonLabel={formatMessage({
                    id: "admin.deactivateDoc.confirmationArchiveButton",
                  })}
                  buttonIcon="archive"
                  buttonVariant="darkGreen"
                />
              )}
              {systemStatus === "new" && (
                <ConfirmationButton
                  messageTitle={name}
                  onClick={handleOnDelete}
                  onSuccess={onDeleteSuccess}
                  confirmationMessage={formatMessage({
                    id: "admin.deleteSportProduct.confirmationText",
                  })}
                  confirmationButtonLabel={formatMessage({
                    id: "admin.deleteSportProduct.button.label",
                  })}
                  confirmationButtonIconColor="white"
                  buttonIcon="trash"
                  buttonVariant="primary"
                />
              )}
            </ArchiveWrapper>
          </GridItem>
        )}
        <GridItem col={[1, 11]} medium={[1, 12]}>
          <ContentBox>
            <FormItem>
              <FormItemInput>
                <FormInput
                  data-cy="sport-product-form-title"
                  displayAs={H1}
                  name="name"
                  trimLineBreaks
                  bigPadding
                  autoFocus={!name || !name.length}
                />
              </FormItemInput>
            </FormItem>

            <FormItem horizontal>
              <FormItemLabel htmlFor="type">
                <FormattedMessage id="form.productStatus.label" />
              </FormItemLabel>
              <FormItemInput
                css={{ "@mdUp": { maxWidth: "400px" } }}
                data-cy="sport-product-status"
              >
                <WrappedDropdownSelect
                  name="productStatus"
                  options={mappedOptions.productStatuses}
                  noDefault
                />
              </FormItemInput>
            </FormItem>
          </ContentBox>
        </GridItem>
      </Grid>

      <Grid>
        <GridItem col={[1, 3]} medium={[1, 12]}>
          <H3 css={{ "@mdUp": { textAlign: "right" } }} noMargin>
            <Message id="form.section.specifications.title" />
          </H3>
        </GridItem>

        <GridItem col={[4, 8]} medium={[1, 12]}>
          <ContentBox>
            <FormItem horizontal>
              <FormItemLabel htmlFor="disciplines">
                <FormattedMessage id="form.disciplines.label" />
              </FormItemLabel>
              <FormItemInput data-cy="sport-product-form-discipline">
                <WrappedMultiselectDropdown
                  name="disciplines"
                  options={mappedOptions.disciplines}
                />
              </FormItemInput>
            </FormItem>

            <FormItem horizontal>
              <FormItemLabel htmlFor="location">
                <FormattedMessage id="form.location.label" />
              </FormItemLabel>
              <FormItemInput data-cy="sport-product-form-location">
                <WrappedDropdownSelect
                  name="location"
                  options={mappedOptions.locations}
                  noDefault
                />
              </FormItemInput>
            </FormItem>

            <FormItem horizontal>
              <FormItemLabel htmlFor="type">
                <FormattedMessage id="form.type.label" />
              </FormItemLabel>
              <FormItemInput data-cy="sport-product-form-type">
                <WrappedDropdownSelect
                  name="type"
                  options={mappedOptions.sportProductTypes || []}
                  noDefault
                />
              </FormItemInput>
            </FormItem>

            <FormItem horizontal>
              <FormItemLabel htmlFor="subtype">
                <FormattedMessage id="form.subtype.label" />
              </FormItemLabel>
              <FormItemInput data-cy="sport-product-form-subtype">
                <WrappedDropdownSelect
                  name="subtype"
                  options={mappedOptions.sportProductSubtypes}
                  noDefault
                />
              </FormItemInput>
            </FormItem>

            <FormItem horizontal>
              <FormItemLabel htmlFor="description">
                <FormattedMessage id="form.description.label" />
              </FormItemLabel>
              <FormItemInput data-cy="sport-product-form-description">
                <FormInput
                  displayAs={P}
                  name="description"
                  trimLineBreaks
                  italic
                />
              </FormItemInput>
            </FormItem>

            <FormItem horizontal>
              <FormItemLabel htmlFor="sustainabilityLabel">
                <FormattedMessage id="form.sustainabilityLabel.label" />
              </FormItemLabel>
              <FormItemInput data-cy="sport-product-form-sustainabilityLabel">
                <WrappedDropdownSelect
                  name="sustainabilityLabel"
                  options={mappedOptions.sustainabilityLabels}
                  placeholder={formatMessage({
                    id: "form.sustainabilityLabel.placeholder",
                  })}
                  noDefault
                />
              </FormItemInput>
            </FormItem>

            <FormItem horizontal>
              <FormItemLabel htmlFor="manufacturer">
                <FormattedMessage id="form.manufacturer.label" />
              </FormItemLabel>
              <FormItemInput data-cy="sport-product-form-manufacturer">
                <WrappedDropdownSelect
                  name="manufacturer"
                  options={mappedOptions.manufacturers}
                  noDefault
                />
              </FormItemInput>
            </FormItem>

            <FormItem horizontal>
              <FormItemLabel htmlFor="contractors">
                <FormattedMessage id="form.contractors.label" />
              </FormItemLabel>
              <FormItemInput data-cy="sport-product-form-contractors">
                <WrappedMultiselectDropdown
                  name="contractors"
                  options={mappedOptions.contractors}
                />
              </FormItemInput>
            </FormItem>

            <FormItem horizontal>
              <FormItemLabel htmlFor="testInstitute">
                <FormattedMessage id="form.institute.label" />
              </FormItemLabel>
              <FormItemInput data-cy="sport-product-form-testInstitute">
                <WrappedDropdownSelect
                  name="testInstitute"
                  options={mappedOptions.institutes}
                  noDefault
                />
              </FormItemInput>
            </FormItem>
          </ContentBox>
        </GridItem>
      </Grid>

      <Grid>
        <GridItem col={[1, 3]} medium={[1, 12]}>
          <H3 css={{ "@mdUp": { textAlign: "right" } }} noMargin>
            <Message id="form.section.precepts.title" />
          </H3>
        </GridItem>

        <GridItem col={[4, 8]} medium={[1, 12]}>
          <ContentBox>
            {/* <FormItem>
              <FormItemLabel htmlFor='precepts'>
                <FormattedMessage id='form.precepts.label' />
              </FormItemLabel>
              <FormItemInput>
                <WrappedDropdownSelect name='precepts' options={mappedOptions.[]}  noDefault />
              </FormItemInput>
            </FormItem> */}

            <FormItem>
              <FormItemLabel>
                <FormattedMessage id="form.sportsProduct.normLinks.label" />
              </FormItemLabel>
              <FormItemInput data-cy="sport-product-form-normLinks">
                {normLinks.fields.map((link, index) => (
                  <div key={link._id} {...motionProps}>
                    <LinkFormItem
                      data-cy="sport-product-form-normLinks-item"
                      path={`normLinks.${index}`}
                      remove={normLinks.remove}
                      removeFromIndex={index}
                    />
                  </div>
                ))}

                <Button
                  data-cy="sport-product-form-add-link"
                  variant="secondary"
                  icon="plusThin"
                  size="small"
                  onClick={handleAddLinkClick}
                >
                  <Message id="button.addLink.label" />
                </Button>
              </FormItemInput>
            </FormItem>
          </ContentBox>
        </GridItem>
      </Grid>

      <Grid>
        <GridItem col={[1, 3]} medium={[1, 12]}>
          <H3 css={{ "@mdUp": { textAlign: "right" } }} noMargin>
            <Message id="form.section.reports.title" />
          </H3>
        </GridItem>

        <GridItem col={[4, 8]} medium={[1, 12]}>
          <ContentBox>
            <FormItem>
              <FormItemLabel>
                <FormattedMessage id="form.sportsProduct.reports.label" />
              </FormItemLabel>

              <FormItemInput data-cy="sport-product-form-reports">
                {reports.fields.map((report, index) => (
                  <div key={report._id} {...motionProps}>
                    <ReportFormItem
                      path={`reports.${index}`}
                      remove={reports.remove}
                      removeFromIndex={index}
                    />
                  </div>
                ))}

                <Button
                  variant="secondary"
                  icon="plusThin"
                  size="small"
                  onClick={handleAddReportClick}
                  data-cy="sport-product-form-reports-button"
                >
                  <Message id="button.addReport.label" />
                </Button>
              </FormItemInput>
            </FormItem>
          </ContentBox>
        </GridItem>
      </Grid>
    </Form>
  )
}
