import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from "body-scroll-lock"
import React, {
  Fragment,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react"
import { iconSizes } from "src/constants"
import {
  IInfrastructureDrawingVariants,
  IInfrastructureRuleAttachment,
} from "src/services"
import { styled } from "src/stitches.config"

import { DrawingVariantFlatIcon } from "../../assets-icons"
import { DrawingModalTitle } from "../../drawing-modal-title"
import { Message } from "../../message"
import { Modal } from "../../modal"

interface Props extends IInfrastructureRuleAttachment {
  url: IInfrastructureDrawingVariants
  isTextOnlyRule: boolean // if true, we need to tweak the positioning a bit
}

const RuleAttachmentDrawingWrapper = styled("div", {
  marginTop: "$30",

  img: {
    width: "calc(100% + $sizes$30)",
    padding: "$20",
    borderRadius: "0 0 4px 4px",
    backgroundColor: "$background",
    "@mdDown": {
      padding: "$10",
    },
    "@mdUp": {
      width: "calc(110%)",
    },

    "@lgUp": {
      width: "calc(180%)",
    },

    "@xlUp": {
      width: "calc(186%)",
    },
  },

  variants: {
    isTextOnlyRule: {
      true: {
        img: {
          "@mdUp": {
            width: "calc(110% - 51px)",
          },

          "@lgUp": {
            width: "calc(180% - 51px)",
          },

          "@xlUp": {
            width: "calc(186% - 51px)",
          },
        },
      },
    },
  },
})

const ImageCaption = styled("div", {
  paddingTop: "$10",
  fontFamily: "$grotesk",
  fontSize: "$bodySmall",
  lineHeight: "1.25",
})

const FullscreenDrawingWrapper = styled("div", {
  position: "absolute",
  marginTop: "-25px",
  right: "0",
  width: "280px",
  display: "flex",
  transform: "translateX(100%)",
  cursor: "pointer",

  span: {
    color: "$blue",
    textDecoration: "underline",
  },

  "@lgDown": {
    display: "none",
  },
})

const RuleAttachmentDrawingIcon = styled("div", {
  position: "relative",
  flex: "0 0 $sizes$30",
  height: "$30",
  marginRight: "$10",
  textAlign: "center",
  color: "$primary",
  borderRadius: "0 4px 4px 0",
  backgroundColor: "$white",
  boxShadow: "$default",

  // Hides shadow on the left side
  "&::after": {
    position: "absolute",
    top: "0px",
    bottom: "-5px",
    left: "-5px",
    width: "5px",
    content: "''",
    backgroundColor: "$white",
  },
})

export const RuleAttachmentDrawing = ({
  url,
  title,
  isTextOnlyRule,
}: Props) => {
  const [isFullscreenModalOpen, setIsFullscreenModalOpen] = useState(false)
  const handleClick = (
    event: React.MouseEvent<HTMLImageElement | HTMLButtonElement>
  ) => {
    setIsFullscreenModalOpen(true)
  }

  const modalRef: MutableRefObject<any> = useRef(null)
  const [modalElement, setModalElement] = useState<any>()

  useEffect(() => {
    if (modalElement) {
      isFullscreenModalOpen
        ? disableBodyScroll(modalElement)
        : enableBodyScroll(modalElement)
    }
  }, [isFullscreenModalOpen])

  useEffect(() => {
    setModalElement(modalRef.current)
    return () => {
      clearAllBodyScrollLocks()
    }
  }, [])

  const onCancelModal = () => {
    setIsFullscreenModalOpen(false)
  }

  return (
    <Fragment>
      <RuleAttachmentDrawingWrapper
        data-cy="drawing-attachment"
        isTextOnlyRule={isTextOnlyRule}
      >
        {url && url.regular && (
          <img
            src={url.regular}
            width="100%"
            height="100%"
            onClick={handleClick}
          />
        )}
        {title && <ImageCaption>{title}</ImageCaption>}
      </RuleAttachmentDrawingWrapper>
      <FullscreenDrawingWrapper data-cy="drawing-attachment-fullscreen">
        <RuleAttachmentDrawingIcon>
          <DrawingVariantFlatIcon size={iconSizes.xlarge} />
        </RuleAttachmentDrawingIcon>
        <span onClick={handleClick}>
          <Message id="drawings.viewBiggerVersion" />
        </span>
      </FullscreenDrawingWrapper>
      <Modal
        ref={modalRef}
        isOpen={isFullscreenModalOpen}
        onCancelClick={onCancelModal}
        headerContent={
          <DrawingModalTitle
            title={title}
            onCancelClick={onCancelModal}
            pdfUrl={url.pdf}
            cadUrl={url.cad}
          />
        }
      >
        <img src={url.large} width="100%" height="100%" />
      </Modal>
    </Fragment>
  )
}
