import { Fragment, useState } from "react"
import { useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"
import {
  ArrowRightAssetsIcon,
  FormItem,
  FormItemInput,
  OwnerToggleButton,
  SearchAssetsIcon,
} from "src/components"
import { ORGANIZATION_TYPES } from "src/constants"

import { MiscDropdown } from "./misc-dropdown"
import { MunicipalitiesDropdown } from "./municipalities-dropdown"

type Mode = ORGANIZATION_TYPES.MISC | ORGANIZATION_TYPES.MUNICIPALITIES

export const OwnerDropdown = ({
  initMode = ORGANIZATION_TYPES.MISC,
}: {
  initMode?: Mode
}) => {
  const { formatMessage } = useIntl()
  const [mode, setMode] = useState<Mode>(initMode)
  const { setValue } = useFormContext()
  const isMunicipalities = (mode: Mode) =>
    mode === ORGANIZATION_TYPES.MUNICIPALITIES

  function handleToggleModeClick() {
    setValue("owner", undefined, {
      shouldValidate: true,
    })

    setMode(
      isMunicipalities(mode)
        ? ORGANIZATION_TYPES.MISC
        : ORGANIZATION_TYPES.MUNICIPALITIES
    )
  }

  return (
    <FormItem>
      <FormItemInput noMargin>
        {isMunicipalities(mode) ? <MunicipalitiesDropdown /> : <MiscDropdown />}
      </FormItemInput>

      <OwnerToggleButton type="button" onClick={handleToggleModeClick}>
        {isMunicipalities(mode) ? (
          <Fragment>
            <SearchAssetsIcon />
            <span>
              {formatMessage({ id: "admin.createProject.ownerSection.kvk" })}
            </span>
          </Fragment>
        ) : (
          <Fragment>
            <ArrowRightAssetsIcon />
            <span>
              {formatMessage({
                id: "admin.createProject.ownerSection.municipality",
              })}
            </span>
          </Fragment>
        )}
      </OwnerToggleButton>
    </FormItem>
  )
}
