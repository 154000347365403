import uniqBy from "lodash.uniqby"
import { Status } from "src/services"
import {
  AccommodationData,
  AccommodationPassport,
  Discipline,
  IDisciplineCertificate,
  IImplementedSportProduct,
  IOrganization,
  Sport,
  TAssociation,
  TUserOrganizations,
} from "src/services"
import { useHasRoles, useIsAdmin } from "src/utils/has-permissions"

interface IApprovedSportClub {
  id: number
  name: string
}

/**
 * Returns the user's relations to accommodation
 */
export const useUserAccommodation = ({
  accommodation,
  userOrganizations,
}: {
  accommodation: AccommodationData | AccommodationPassport
  userOrganizations: TUserOrganizations
}) => {
  const { association, sportClub, manufacturer, consultant, contractor } =
    userOrganizations

  const accommodationOwner = accommodation.owner
  const userOrganizationOwner = !!accommodationOwner?.type
    ? (userOrganizations[
        accommodationOwner.type as keyof typeof userOrganizations
      ] as IOrganization | null)
    : null

  const isOwnerLinkedToUserOrganizations =
    !!accommodationOwner?.type &&
    userOrganizationOwner?.id === accommodationOwner?.id
  const approvedSportClubs = getApprovedSportClubs(
    accommodation.implementedSportProducts
  )
  const userIsOwner = useHasRoles(["owner"])

  const consultantId = accommodation.implementedSportProducts[0]?.consultant?.id
  const contractorId = accommodation.implementedSportProducts[0]?.contractor?.id
  const mainContractorId =
    accommodation.implementedSportProducts[0]?.mainContractor?.id

  return {
    userAssociation: association,
    isSportClubMember:
      !!sportClub &&
      approvedSportClubs
        .map((approvedSportClub) => approvedSportClub.id)
        .includes(sportClub.id),
    isManufactureEmployee:
      !!manufacturer &&
      manufacturer.id ===
        accommodation.implementedSportProducts[0]?.sportProduct?.manufacturer
          ?.id,
    accommodationOwner,
    isOwner: userIsOwner && isOwnerLinkedToUserOrganizations,
    isConsultantEmployee: !!consultant && consultant?.id === consultantId,
    isContractorEmployee: !!contractor && contractor?.id === contractorId,
    isMainContractorEmployee:
      !!contractor && contractor?.id === mainContractorId,
  }
}

/**
 * Returns the user's permissions for accommodation according to his relation to it
 */
export const useAccommodationPermissions = ({
  accommodation,
  userOrganizations,
}: {
  accommodation: AccommodationData | AccommodationPassport
  userOrganizations: TUserOrganizations
}) => {
  const {
    isOwner,
    isManufactureEmployee,
    isConsultantEmployee,
    isContractorEmployee,
    isMainContractorEmployee,
    userAssociation,
  } = useUserAccommodation({
    accommodation,
    userOrganizations,
  })

  const { sportClub } = userOrganizations
  const isAdmin = useIsAdmin()

  const disciplineCertificates =
    accommodation.implementedSportProducts[0]?.disciplineCertificates || []

  const userAssociationRelatedToCertificatedSport =
    !!disciplineCertificates.find(
      (disciplineCertificate) =>
        disciplineCertificate.state === Status.APPROVED &&
        disciplineCertificateRelatedToUser({
          disciplineCertificate,
          userSportClub: sportClub,
          userAssociation,
        })
    )

  const canViewCertificates =
    isAdmin ||
    isConsultantEmployee ||
    isOwner ||
    isManufactureEmployee ||
    userAssociationRelatedToCertificatedSport

  const canViewReport =
    canViewCertificates ||
    isOwner ||
    isManufactureEmployee ||
    isContractorEmployee ||
    isMainContractorEmployee

  const canViewApprovedCertificate =
    isAdmin ||
    isConsultantEmployee ||
    isContractorEmployee ||
    isMainContractorEmployee ||
    isOwner

  return {
    canViewCertificates,
    canViewReport,
    canViewApprovedCertificate,
  }
}

/**
 * Checks if user has a relation to discipline certificate
 */
export const disciplineCertificateRelatedToUser = ({
  disciplineCertificate,
  userSportClub,
  userAssociation,
}: {
  disciplineCertificate: IDisciplineCertificate
  userSportClub: IOrganization | null
  userAssociation: TAssociation | null
}) => {
  const sportClubIds = disciplineCertificate.sportClubs.map((club) => club.id)

  if (
    disciplineCertificate.discipline.sport.association?.code ===
    userAssociation?.code
  )
    return true

  return !!userSportClub && !!sportClubIds.includes(userSportClub.id)
}

/**
 * Returns accommodation sport clubs which are connected to approved discipline certificate
 */
export const getApprovedSportClubs = (
  implementedSportProducts: IImplementedSportProduct[]
) => {
  return uniqBy(
    Array.from(
      implementedSportProducts.reduce(
        (approvedSportClubs, implementedSportProduct) => {
          for (const disciplineCertificate of implementedSportProduct.disciplineCertificates) {
            if (disciplineCertificate.state === Status.APPROVED) {
              for (const sportClub of disciplineCertificate.sportClubs) {
                approvedSportClubs.add({
                  id: sportClub.id,
                  name: sportClub.name,
                })
              }
            }
          }

          return approvedSportClubs
        },
        new Set()
      )
    ),
    "id"
  ) as IApprovedSportClub[]
}

/**
 * Returns accommodation sport names which are connected to approved certificate
 */
export const getApprovedSportNames = (
  implementedSportProduct: IImplementedSportProduct
) => {
  if (!implementedSportProduct?.disciplineCertificates.length) return []

  return Array.from(
    implementedSportProduct.disciplineCertificates.reduce(
      (sports, disciplineCertificate) => {
        if (
          disciplineCertificate.state === Status.APPROVED &&
          implementedSportProduct.disciplines.some(
            (discipline) =>
              discipline.code === disciplineCertificate.discipline.code
          )
        ) {
          sports.add(disciplineCertificate.discipline.sport.name)
        }

        return sports
      },
      new Set()
    )
  ) as Sport[]
}

/**
 * Returns accommodation disciplines names which are connected to approved certificate
 */
export const getApprovedDisciplineNames = (
  implementedSportProduct: IImplementedSportProduct
) => {
  if (!implementedSportProduct?.disciplineCertificates.length) return []

  return Array.from(
    implementedSportProduct.disciplineCertificates.reduce(
      (disciplines, disciplineCertificate) => {
        if (
          disciplineCertificate.state === Status.APPROVED &&
          implementedSportProduct.disciplines.some(
            (discipline) =>
              discipline.code === disciplineCertificate.discipline.code
          )
        ) {
          disciplines.add(disciplineCertificate.discipline.name)
        }

        return disciplines
      },
      new Set()
    )
  ) as Discipline[]
}

/**
 * Returns approved certificate
 */
export const getApprovedCertificates = ({
  canViewApprovedCertificate,
  implementedSportProduct,
  userOrganizations,
}: {
  canViewApprovedCertificate: boolean
  implementedSportProduct: IImplementedSportProduct
  userOrganizations: TUserOrganizations
}) => {
  if (!implementedSportProduct?.disciplineCertificates.length) return []

  return uniqBy(
    implementedSportProduct.disciplineCertificates
      .filter((disciplineCertificate) => {
        if (disciplineCertificate.state === Status.APPROVED) {
          if (canViewApprovedCertificate) return true

          const userRelatedToDiscipline = disciplineCertificateRelatedToUser({
            disciplineCertificate,
            userSportClub: userOrganizations.sportClub,
            userAssociation: userOrganizations.association,
          })

          return userRelatedToDiscipline
        }

        return false
      })
      .filter(Boolean)
      .map((disciplineCertificate) => disciplineCertificate),
    (cer) => cer.discipline.sport.code
  )
}
