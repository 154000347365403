import type * as Stitches from "@stitches/react"
import { useMemo } from "react"
import { useUserSubscriptions } from "src/hooks/subscription"
import { useSubscribe } from "src/hooks/subscription"
import { EventType } from "src/services/api"
import { styled } from "src/stitches.config"

import { Toggle } from "../toggle"

interface Props {
  type: EventType
  textContent?: React.ReactNode
  css?: Stitches.CSS
}

const ToggleWrapper = styled("div", {
  display: "flex",
  gap: "10px",
  alignItems: "center",
  color: "$body",
  justifyContent: "flex-end",
})

// TODO: add test for this
export const useChecked = (type: EventType) => {
  const { data } = useUserSubscriptions()
  return useMemo(() => {
    if (!!data) {
      return data.some((subscription) => subscription.type === type)
    }
    return false
  }, [data, type])
}

export const SubscriptionToggle = ({ type, textContent, css }: Props) => {
  const checked = useChecked(type)
  const { isLoading } = useUserSubscriptions()
  const { mutateAsync, isLoading: isMutating } = useSubscribe(checked)

  const disabled = useMemo(
    () => isMutating || isLoading,
    [isMutating, isLoading]
  )

  return (
    <ToggleWrapper css={css}>
      {textContent}

      <Toggle
        disabled={disabled}
        checked={checked}
        subscriptionStyle
        onChange={async () => {
          await mutateAsync({ type })
        }}
      />
    </ToggleWrapper>
  )
}
